import moment from "moment";
import React, { useEffect, useState } from "react";
import { getFormattedDate, systemDateFormat } from "utils/dates";
import DayElement from "./DayElement";
import FrequencyModal from "./FrequencyModal";

const FrequencyBar = ({ days }) => {
  const mainDivStyle = { width: "100px" };

  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const newData = [];
    const dates =
      days?.length > 0 ? days.map((date) => getFormattedDate(date)) : [];
    for (let index = 0; index < 7; index++) {
      const day = moment()
        .add(-index, "days")
        .startOf("day")
        .format(systemDateFormat);
      const log = dates.includes(day);
      newData.unshift({
        day: moment(day).format("ddd"),
        log,
      });
    }
    setData(newData);
  }, [days]);

  return (
    <>
      <div style={mainDivStyle} className="d-flex gap-10">
        {data.map((x, index) => (
          <div key={index}>
            <DayElement date={x} />
          </div>
        ))}
      </div>
      <p onClick={() => setShowModal(true)} className="m-0 link-blue pointer">
        view more
      </p>
      {showModal && (
        <FrequencyModal
          onClose={() => setShowModal(false)}
          days={days}
          showModal
        />
      )}
    </>
  );
};

export default FrequencyBar;
