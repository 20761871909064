export const capitalize = (str) => {
  try {
    return str[0].toUpperCase() + str.slice(1).toLowerCase();
  } catch (error) {
    return str;
  }
};

export const getGenderString = (gender) => {
  switch (gender) {
    case "f":
    case "F":
      return "Female";
    case "m":
    case "M":
      return "Male";
    case "u":
    case "U":
      return "I'd rather not say";
    default:
      break;
  }
};

export const getUserFormalityLabel = (user) => {
  switch (user) {
    case "thriver":
      return "Thrivers";
    case "formalCaregiver":
      return "Formal caregivers";
    case "informalCaregiver":
      return "Informal caregivers";
    default:
      return "";
  }
};

export const filterNameEmail = ({ value, users }) => {
  try {
    const reg = new RegExp(value, "gi");
    return users.filter((v) => reg.test(v.name) || reg.test(v.email));
  } catch (error) {
    return users;
  }
};

export const getInitials = (name) => {
  try {
    return (
      name
        .split(" ")
        .map((n) => n[0])[0]
        .toUpperCase() +
      name
        .split(" ")
        .map((n) => n[0])[1]
        .toUpperCase()
    );
  } catch (error) {
    try {
      return name[0].toUpperCase();
    } catch (error) {
      return "A";
    }
  }
};

export const plural = (str, value) => {
  try {
    return value === 1 ? str : `${str}s`;
  } catch (error) {
    return str;
  }
};

export const capitalizeFirstLetter = (string) => {
  try {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } catch (error) {
    return string;
  }
};

export const stringArraysEqual = (a, b) =>
  a.length === b.length && a?.every((v, i) => v === b[i]);
