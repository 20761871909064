const ButtonsContainer = ({
  children,
  center = false,
  start = false,
  noPadding = false,
}) => {
  return (
    <div
      className="d-flex flex-wrap gap-10"
      style={{
        justifyContent: center ? "center" : start ? "start" : "end",
        padding: noPadding ? "0" : "1rem 0",
      }}
    >
      {children}
    </div>
  );
};

export default ButtonsContainer;
