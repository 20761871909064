import React from "react";
import { CCard } from "@coreui/react";
import "./Card.scss";

const Card = ({
  title = "",
  noPadding = false,
  style = {},
  footer,
  className,
  children,
}) => {
  return (
    <CCard
      className={`my_card ${className}`}
      style={{ ...style, padding: noPadding ? 0 : "1rem" }}
    >
      <span className="mb-2 ml-1" style={{ fontWeight: "600" }}>
        {title}
      </span>
      <div style={{ height: "inherit" }}>{children}</div>
      <div style={{ height: "auto" }}>{footer}</div>
    </CCard>
  );
};

export default Card;
