import React, { useState } from "react";
import { CButton, CModal } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import "./FilterButton.scss";
import { useSelector } from "react-redux";
import { store_getActiveFilter } from "store/filters.store";

const FilterButton = ({ children }) => {
  const [showFilters, setShowFilters] = useState(false);
  const open = () => setShowFilters(true);
  const close = () => setShowFilters(false);

  const filters = useSelector(store_getActiveFilter);

  return (
    <div>
      <CButton color="primary" className="filter_button" onClick={open}>
        <CIcon style={{ transform: "translateY(-1px)" }} name="cil-filter" />
        <span>Filter {filters ? `(${filters})` : ""}</span>
      </CButton>
      <CModal
        style={{ position: "absolute" }}
        show={showFilters}
        onClose={close}
      >
        <CIcon
          name="cil-X"
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={close}
          className="mb-1 mt-3 mr-3"
        />
        {children}
      </CModal>
    </div>
  );
};

export default FilterButton;
