export const getSubscriptionId = (user) => {
  const { subscriptions } = user;
  if (!subscriptions) return "";
  let subscriptionId = subscriptions[0]?.identifier || "";
  for (let subscription of subscriptions) {
    // TODO check if is a better way to get subsId
    if (
      subscription.status !== "canceled" &&
      subscription.thrivers - subscription.linkedThrivers > 0
    ) {
      subscriptionId = subscription.identifier;
      break;
    }
  }
  return subscriptionId;
};

export function getAvailableSubscriptions(user) {
  const { subscriptions } = user;
  const minSubscriptions = 10;
  let totalSubscriptionsAvailable = 0;
  let totalLinkedThrivers = 0;
  for (let subscription of subscriptions || []) {
    totalSubscriptionsAvailable += subscription?.thrivers || 0;
    totalLinkedThrivers += subscription?.linkedThrivers || 0;
  }
  const totalAvailable = totalSubscriptionsAvailable - totalLinkedThrivers;
  return {
    numberAvailable: totalAvailable,
    totalLinkedThrivers: totalLinkedThrivers,
    totalSubscriptions: totalSubscriptionsAvailable,
    showNotification: totalAvailable < minSubscriptions,
  };
}
