import React from "react";
import { CButton } from "@coreui/react";
import CIcon from "@coreui/icons-react";

import "./Buttons.scss";

const EditButton = ({ onClick = () => null, showBorder = false }) => {
  return (
    <span>
      <CButton
        className={`buttons editButton ${showBorder ? "bordered" : ""}`}
        onClick={onClick}
      >
        <CIcon name="cil-Pencil" />
        EDIT
      </CButton>
    </span>
  );
};

export default EditButton;
