import FilterInput from "./FilterInput";
import { ButtonsContainer } from "components";
import HealthFilterLogic from "./Filter.logic";

export default function HealthFilter({
  allUsers,
  onChangeFilters,
}) {
  const { filters, changeFilter, resetFilters } = HealthFilterLogic({
    allUsers,
    onChangeFilters,
  });

  return (
    <>
      <div className="pb-2 pt-3 px-3 d-flex flex-column">
        {FilterInput({
          value: filters.ageCategory,
          name: "ageCategory",
          label: "Age category",
          options: [
            { value: "any", label: "Any" },
            { value: "<50", label: "<50" },
            { value: "51-60", label: "51-60" },
            { value: "61-70", label: "61-70" },
            { value: "71-80", label: "71-80" },
            { value: "81>", label: "81>" },
          ],
          onChange: changeFilter,
        })}

        {FilterInput({
          value: filters.gender,
          name: "gender",
          label: "Gender",
          options: [
            { value: "any", label: "Any" },
            { value: "m", label: "Male" },
            { value: "f", label: "Female" },
            { value: "u", label: "I'd rather not say" },
          ],
          onChange: changeFilter,
        })}
      </div>

      <ButtonsContainer noPadding>
        <span
          className="link-dark pointer p-3 pt-0 mb-2"
          onClick={resetFilters}
        >
          Reset filters
        </span>
      </ButtonsContainer>
    </>
  );
}
