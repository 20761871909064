import CIcon from "@coreui/icons-react";
import { CButton } from "@coreui/react";

const IconButton = ({
  onClick = () => null,
  icon,
  name,
  color = "dark",
  title = "",
  width,
  disabled = false,
  smallButton = false,
}) => {
  return (
    <CButton
      title={title}
      disabled={disabled}
      color={color}
      size="sm"
      onClick={onClick}
      style={{
        width: width || "fit-content",
        fontSize: "0.8rem",
      }}
      className={smallButton ? "py-0 px-1" : ""}
    >
      <CIcon
        className={smallButton ? "m-0" : ""}
        style={{ transform: "translateY(-1px)" }}
        name={icon}
      />
      {name && <span className="ml-1">{name}</span>}
    </CButton>
  );
};

export default IconButton;
