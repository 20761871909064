import { useMemo } from "react";

import { getRoutes } from "navigation/routes";
import { getLocalStorageLocation, getLocalViewAs } from "utils/localStorage";

const TheContentLogic = () => {
  const location = getLocalStorageLocation(); // To see if its from UK
  const viewAs = getLocalViewAs(); // Check if the super admin is  using viewAs feature

  // Get routes based on dashboard permissions
  const routes = useMemo(
    () => getRoutes({ location, viewAs }),
    [viewAs, location]
  );

  return { routes };
};

export default TheContentLogic;
