import React from "react";
import { Link } from "react-router-dom";

import { IconButton } from "components";
import { Role } from "utils";

const EditUserButton = ({ user, tableType }) => {
  const canEdit = () => {
    if (Role.careHomeAccess()) {
      // TODO use userTableTypes from constants
      if (tableType === "users" && user.type === "thriver") return true;
      if (tableType === "users" && user.type === "caregiver") return true;
      if (tableType === "caregivers" && user.type === "caregiver") return true;
    }
    if (Role.caregiverAccess()) {
      if (tableType === "users" && user.type === "thriver") return true;
    }
    if (Role.adminAccess()) {
      if (tableType === "carehomes") return true;
      if (tableType === "users" && user.type === "carehome") return true;
    }
    return false;
  };

  return canEdit() ? (
    <Link
      to={{
        pathname: `/user-profile`,
        state: { id: user.identifier },
      }}
    >
      <IconButton
        name="Edit user"
        title={`Edit ${user.name}`}
        color="primary"
        icon="cil-pencil"
      />
    </Link>
  ) : null;
};

export default EditUserButton;
