import React from "react";
import {
  CInput,
  CInputGroup,
  CInputGroupText,
  CInputGroupAppend,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";

import NameEmailFilterLogic from "./NameEmailFilter.logic";
import "./NameEmailFilter.scss";

const NameEmailFilter = ({
  users = [],
  onFilterChange = () => null,
  margin = true,
  placeholder,
}) => {
  const { value, onChange, onReset } = NameEmailFilterLogic({
    users,
    onFilterChange,
  });

  return (
    <CInputGroup className={`name-email-input ${margin ? "my-3" : ""}`}>
      <CInput
        type="text"
        placeholder={placeholder || "Search ..."}
        onChange={onChange}
        value={value}
      />
      <CInputGroupAppend>
        <CInputGroupText
          className={value ? "pointer" : ""}
          onClick={value ? onReset : () => null}
        >
          <CIcon name={value ? "cil-X" : "cil-magnifying-glass"} />
        </CInputGroupText>
      </CInputGroupAppend>
    </CInputGroup>
  );
};

export default NameEmailFilter;
