import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { store_getAuthUser } from "../../pages/Auth/Auth.store";
import { getAvailableSubscriptions } from "../../utils/users";

const NotificationsDot = ({ absolute }) => {
  const user = useSelector(store_getAuthUser);
  const [notificationsPending, setNotificationsPending] = useState(false);

  useEffect(() => {
    if (user?.type === "carehome") {
      const { showNotification } = getAvailableSubscriptions(user);
      setNotificationsPending(showNotification);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return notificationsPending ? (
    <div
      style={{
        position: absolute ? "absolute" : "relative",
        background: "#e36e00",
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        top: absolute ? 4 : 0,
        right: 0,
        marginRight: absolute ? 0 : "5px",
      }}
    />
  ) : null;
};
export default NotificationsDot;
