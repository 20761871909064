import moment from "moment/moment";
import { store } from "../../store";
import { systemDateFormat } from "./../../utils/dates";

export const dateTypesKeys = {
  today: "today",
  last31days: "last31days",
  yesterday: "yesterday",
  thisMonth: "thisMonth",
  lastMonth: "lastMonth",
  thisYear: "thisYear",
  lastYear: "lastYear",
  last7days: "last7days",
  customMonth: "customMonth",
};

export const getFirstAndSecondDates = ({ dateType, customDate }) => {
  if (!dateType) return;

  // Custom month
  if (customDate && dateType === dateTypesKeys.customMonth) {
    const date = moment(customDate);
    const firstDate = date.startOf("month").format(systemDateFormat);
    const secondDate = date.endOf("month").format(systemDateFormat);
    return { firstDate, secondDate };
  }

  // Default dates
  let firstDateAux = null;
  let secondDateAux = null;
  let month = moment().format("M");
  let year = moment().format("YYYY");

  switch (dateType) {
    case dateTypesKeys.last31days:
      firstDateAux = moment()
        .startOf("day")
        .add(-30, "days")
        .format(systemDateFormat);
      secondDateAux = moment().startOf("day").format(systemDateFormat);
      break;
    case dateTypesKeys.today:
      firstDateAux = moment().startOf("day").format(systemDateFormat);
      secondDateAux = firstDateAux;
      break;
    case dateTypesKeys.yesterday:
      firstDateAux = moment()
        .startOf("day")
        .add(-1, "days")
        .format(systemDateFormat);
      secondDateAux = firstDateAux;
      break;
    case dateTypesKeys.thisMonth:
      firstDateAux = moment(`${year}/${month}/01`)
        .startOf("day")
        .format(systemDateFormat);
      secondDateAux = moment(firstDateAux)
        .add(1, "month")
        .add(-1, "days")
        .format(systemDateFormat);
      break;
    case dateTypesKeys.lastMonth:
      month = month === 1 ? 12 : month - 1;
      year = month === 12 ? year - 1 : year;

      firstDateAux = moment(`${year}/${month}/01`)
        .startOf("day")
        .format(systemDateFormat);
      secondDateAux = moment(firstDateAux)
        .add(1, "month")
        .add(-1, "days")
        .format(systemDateFormat);
      break;
    case dateTypesKeys.thisYear:
      firstDateAux = moment(`${year}/01/01`)
        .startOf("day")
        .format(systemDateFormat);
      secondDateAux = moment(firstDateAux)
        .add(1, "year")
        .add(-1, "days")
        .format(systemDateFormat);
      break;
    case dateTypesKeys.lastYear:
      firstDateAux = moment(`${year - 1}/01/01`)
        .startOf("day")
        .format(systemDateFormat);
      secondDateAux = moment(firstDateAux)
        .add(1, "year")
        .add(-1, "days")
        .format(systemDateFormat);
      break;
    case dateTypesKeys.last7days:
      firstDateAux = moment()
        .startOf("day")
        .add(-1, "week")
        .format(systemDateFormat);
      secondDateAux = moment().startOf("day").format(systemDateFormat);
      break;
    default:
      break;
  }
  return { firstDate: firstDateAux, secondDate: secondDateAux };
};

export const getFormattedDates = () => {
  let dates = {
    date1: null,
    date2: null,
  };

  const { startDate, endDate } = store.getState()?.dashboard?.dates;

  if (startDate && endDate) {
    dates.date1 = new Date(moment.utc(startDate).startOf("day")).toISOString();
    dates.date2 = new Date(
      moment.utc(endDate).startOf("day").add(1, "day").add(-1, "millisecond")
    ).toISOString();
  }
  return dates;
};

export const checkedIn = (date, label, dateType) => {
  switch (dateType) {
    case dateTypesKeys.last31days:
    case dateTypesKeys.thisMonth:
    case dateTypesKeys.lastMonth:
    case dateTypesKeys.customMonth:
      if (moment(date).isSame(label, "day")) return true;
      break;
    case dateTypesKeys.today:
    case dateTypesKeys.yesterday:
      if (moment(date).format("HH") === label.slice(0, 2)) return true;
      break;
    case dateTypesKeys.thisYear:
    case dateTypesKeys.lastYear:
      if (moment(date).format("MM") === moment().month(label).format("MM"))
        return true;
      break;
    case dateTypesKeys.last7days:
      if (moment(date).format("DD") === label.slice(-2)) return true;
      break;
    default:
      return false;
  }
};

export const getLabels = (date, dateType) => {
  const lb = [];
  const firstDate = moment(date).startOf("day");

  switch (dateType) {
    case dateTypesKeys.last31days:
      for (let i = 0; i < 31; i++)
        lb.push(moment(firstDate).add(i, "days").format(systemDateFormat));
      break;

    case dateTypesKeys.today:
    case dateTypesKeys.yesterday:
      for (let i = 0; i < 24; i++) lb.push(`${("0" + i).slice(-2)}hrs`);
      break;

    case dateTypesKeys.thisMonth:
    case dateTypesKeys.lastMonth:
    case dateTypesKeys.customMonth:
      const daysInMonth = moment(firstDate).daysInMonth();
      for (let i = 0; i < daysInMonth; i++)
        lb.push(moment(firstDate).add(i, "days").format(systemDateFormat));
      break;

    case dateTypesKeys.thisYear:
    case dateTypesKeys.lastYear:
      for (let i = 0; i < 12; i++)
        lb.push(moment(firstDate).add(i, "month").format("MMM YYYY"));
      break;

    case dateTypesKeys.last7days:
      for (let i = 0; i <= 7; i++)
        lb.push(moment(firstDate).add(i, "days").format("ddd DD"));
      break;

    default:
      break;
  }
  return lb;
};

export const getFilterTypeTitle = (dateType, customDate) => {
  switch (dateType) {
    case dateTypesKeys.last31days:
      return "in last 31 days";
    case dateTypesKeys.today:
      return "today";
    case dateTypesKeys.yesterday:
      return "yesterday";
    case dateTypesKeys.thisMonth:
      return "this month";
    case dateTypesKeys.lastMonth:
      return "last month";
    case dateTypesKeys.thisYear:
      return "this year";
    case dateTypesKeys.lastYear:
      return "last year";
    case dateTypesKeys.last7days:
      return "in last 7 days";
    case dateTypesKeys.customMonth:
      if (!customDate) return "in custom month";
      const month = moment(customDate).format("MMMM");
      const year = moment(customDate).format("YYYY");
      return `in ${month} ${year}`;
    default:
      return "";
  }
};


export const convertToISO = (startDate, endDate) => {
  if (!startDate || !endDate) return { isoStartDate: "", isoEndDate: "" }

  const s_date = new Date(startDate);
  s_date.setUTCHours(0, 0, 0, 0);
  const isoStartDate = s_date.toISOString();

  const e_date = new Date(endDate);
  e_date.setUTCHours(23, 59, 59, 0);
  const isoEndDate = e_date.toISOString();

  return { isoStartDate, isoEndDate };
}

export const formatCurrentDate = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate
}

export const initialStartDate = () => {
  // Get the current date
  const currentDate = new Date();

  // Calculate the initial date (15 days ago)
  const initialDate = new Date(currentDate);
  initialDate.setDate(currentDate.getDate() - 15);
  const formattedDate = initialDate.toISOString().split('T')[0];
  return formattedDate
}
export const calculateMonthDifference = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const months = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());
  return months;
};