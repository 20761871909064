import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { CContainer, CFade } from "@coreui/react";

import { Spinner } from "components";
import TheContentLogic from "./TheContent.logic";

// Creates the routes based on the user role
const TheContent = () => {
  const { routes } = TheContentLogic();

  return (
    <main className="pt-1 pb-3">
      <CContainer fluid>
        <Suspense fallback={<Spinner grow />}>
          <Switch>
            {routes.map(
              (route, idx) =>
                route.component && (
                  <Route
                    exact
                    key={idx}
                    path={route.path}
                    name={route.name}
                    render={(props) => (
                      <CFade>
                        <route.component {...props} />
                      </CFade>
                    )}
                  />
                )
            )}
            <Redirect from="/" to="/home" />
          </Switch>
        </Suspense>
      </CContainer>
    </main>
  );
};

export default React.memo(TheContent);
