import CIcon from "@coreui/icons-react";
import { CTooltip } from "@coreui/react";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import { getLocalViewAs } from "utils/localStorage";
import { Colors } from "utils";

const ViewAsIcon = () => {
  const viewAs = getLocalViewAs();
  const show = useMemo(() => viewAs && viewAs?.identifier, [viewAs]);

  return show ? (
    <Link to="/home" style={{ color: Colors._black }}>
      <CTooltip content={`Viewing portal as ${viewAs?.email} carehome`}>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            width: "32px",
            height: "32px",
            background: "#f9b115",
            borderRadius: "50%",
          }}
        >
          <CIcon name="cil-zoom" />
        </div>
      </CTooltip>
    </Link>
  ) : null;
};

export default ViewAsIcon;
