import React, { useEffect, useState } from "react";
import { CAlert } from "@coreui/react";
import { getAvailableSubscriptions } from "../../utils/users";

const NotificationsList = ({ user }) => {
  const [notifications, setNotifications] = useState([]);

  const checkSubscriptions = (notifications = []) => {
    const { numberAvailable, showNotification } =
      getAvailableSubscriptions(user);
    if (showNotification)
      notifications.push({
        text: `${
          numberAvailable === 0
            ? "You can't create more care circles"
            : `You can only create ${numberAvailable} more care circles`
        }. Please contact Thriving.ai team to get more`,
        color: numberAvailable === 0 ? "danger" : "warning",
      });
  };

  useEffect(() => {
    const newNotifications = [];
    checkSubscriptions(newNotifications);
    setNotifications(newNotifications);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="text-center">
      {notifications.map(({ text, color = "warning" }, index) => (
        <CAlert key={index} color={color}>
          {text}
        </CAlert>
      ))}
    </div>
  );
};

export default NotificationsList;
