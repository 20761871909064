import {
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import Loading from "./Loaders/Loading";

const ConfirmDialog = ({
  visible,
  message = "Are you sure?",
  buttonText = "Delete",
  buttonColor = "danger",
  handleClose = () => null,
  handleAccept = () => null,
  loading = false,
}) => {
  return (
    <CModal
      onClick={(e) => e.stopPropagation()}
      style={{ color: "#333", cursor: "default" }}
      show={visible}
      onClose={handleClose}
    >
      <CModalHeader>
        <CModalTitle>{buttonText} confirmation</CModalTitle>
      </CModalHeader>
      <CModalBody>
        {message}
        <Loading show={loading} />
      </CModalBody>
      <CModalFooter>
        <CButton disabled={loading} color="secondary" onClick={handleClose}>
          Cancel
        </CButton>
        <CButton disabled={loading} color={buttonColor} onClick={handleAccept}>
          {buttonText}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default ConfirmDialog;
