import { logo } from "./logo";

import * as tempIcons from "@coreui/icons";

export const icons = Object.assign(
  {},
  {
    logo,
  },
  tempIcons
);
