import { CInput, CInputGroupText, CSelect } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import InputGroup from "../Inputs/InputGroup";

const FilterInput = ({ name, label, options, icon, onChange, value }) => {
  return (
    <InputGroup
      prepend={label}
      append={
        icon ? (
          <CInputGroupText className="d-none d-sm-block">
            <CIcon name={icon} />
          </CInputGroupText>
        ) : null
      }
    >
      {options && (
        <CSelect
          onChange={onChange}
          name={name}
          value={value}
          style={{
            borderColor: value === "any" ? "" : "#2187FB",
          }}
        >
          {options.map(({ value, label }, key) => (
            <option key={key} value={value}>
              {label}
            </option>
          ))}
        </CSelect>
      )}

      {!options && (
        <CInput
          value={value}
          onChange={onChange}
          name={name}
          placeholder={label}
          style={{ color: "#2187FB" }}
        />
      )}
    </InputGroup>
  );
};
export default FilterInput;
