import { CLabel, CTextarea } from "@coreui/react";
import { capitalize } from "../../utils/strings";

const TextInput = ({
  name = "",
  maxLength,
  rows = 1,
  label = "",
  value,
  onChange = () => null,
  required = true,
  placeholder = "",
  disabled = false,
  margin = true,
}) => {
  return (
    <>
      {label && (
        <CLabel className={margin ? "mt-3" : ""} htmlFor={`f-${name}`}>
          {label} {required && "*"}
        </CLabel>
      )}
      <CTextarea
        id={`f-${name}`}
        rows={rows}
        placeholder={placeholder || label || capitalize(name)}
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        spellCheck
        maxLength={maxLength || "none"}
        style={{
          resize: rows === 1 ? "none" : "",
          height: rows !== 1 ? "auto" : "2.2rem",
        }}
        disabled={disabled}
      />
    </>
  );
};

export default TextInput;
