import CIcon from "@coreui/icons-react";
import { useIsFetching, useIsMutating, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import { useGetInfoQuery } from "../../pages/Auth/Auth.query";
import Loading from "./Loading";

const GlobalQueryLoader = () => {
  const location = useLocation();

  const queryClient = useQueryClient();

  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  const show = isFetching > 0 || isMutating > 0;

  // Update in profile page
  const { refetch: refetchProfile } = useGetInfoQuery();
  const handleClick = () => {
    queryClient.invalidateQueries();
    if (location?.pathname === "/profile") refetchProfile();
  };

  return (
    <span>
      {show ? (
        <Loading show />
      ) : (
        <CIcon
          title="Refresh data in screen"
          onClick={handleClick}
          name="cil-loop-circular"
          size="2xl"
          className="pointer"
        />
      )}
    </span>
  );
};

export default GlobalQueryLoader;
