import { Colors } from "utils";

const StatusDot = ({ status }) => {
  return (
    <div
      style={{
        background: Colors.getStatusColor(status),
        width: "6px",
        height: "6px",
        borderRadius: "50%",
        display: "inline-block",
        margin: "0 5px 1px 3px",
      }}
    />
  );
};

export default StatusDot;
