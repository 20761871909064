import { toast } from "react-toastify";

const Alert = (function () {
  const toastSettings = {
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    autoClose: 2500,
    hideProgressBar: true,
    position: "top-center",
  };

  const success = (msg) => toast.success(msg, toastSettings);
  const error = (msg) => toast.error(msg, toastSettings);

  return { success, error };
})();

export default Alert;
