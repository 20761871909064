import {
  CInputGroup,
  CInputGroupAppend,
  CInputGroupPrepend,
  CInputGroupText,
} from "@coreui/react";

const InputGroup = ({ append, prepend, children, margin = true }) => {
  return (
    <CInputGroup className={margin ? "mb-3" : ""}>
      {prepend && (
        <CInputGroupPrepend>
          <CInputGroupText>{prepend}</CInputGroupText>
        </CInputGroupPrepend>
      )}
      {children}
      {append && <CInputGroupAppend>{append}</CInputGroupAppend>}
    </CInputGroup>
  );
};

export default InputGroup;
