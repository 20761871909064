const STORE_KEY = "thriving/store";
const STORE_KEY_VIEWAS = "thriving/store/viewAs";

export const isLoggedIn = () => {
  const cachedStore = getLocalStore();
  if (cachedStore) {
    const { token, identifier } = cachedStore;
    return Boolean(token) && Boolean(identifier);
  }
  return false;
};

export function setLocalStore(store) {
  localStorage.setItem(STORE_KEY, JSON.stringify(store));
}

export function getLocalStore() {
  const storeString = localStorage.getItem(STORE_KEY);
  return storeString ? JSON.parse(storeString) : undefined;
}

export function removeLocalStore() {
  localStorage.removeItem(STORE_KEY);
  localStorage.removeItem(STORE_KEY_VIEWAS);
}

// View as specific carehome (for super admin)

export function getLocalViewAs() {
  const storeString = localStorage.getItem(STORE_KEY_VIEWAS);
  return storeString ? JSON.parse(storeString) : undefined;
}
export function setLocalViewAs(client) {
  localStorage.setItem(STORE_KEY_VIEWAS, JSON.stringify(client));
}
export function removeLocalViewAs() {
  localStorage.removeItem(STORE_KEY_VIEWAS);
}

export function getLocalStorageBaseUrl() {
  const storeString = localStorage.getItem(STORE_KEY);
  return storeString
    ? JSON.parse(storeString)?.baseUrl
    : process.env.REACT_APP_BASE_URL;
}

// Check user location
export function getLocalStorageLocation() {
  const storeString = localStorage.getItem(STORE_KEY);
  const location = storeString ? JSON.parse(storeString)?.baseUrl : null;
  if (location?.includes("//uk-api")) return "UK";
  return "US";
}
