import React from "react";
import FrequencyCardsLogic from "./FrequencyCards.logic";
import { Card, SimpleDataCard } from "components";
import "./FrequencyCards.scss";

const FrequencyCards = ({ days, small = true }) => {
  const { data } = FrequencyCardsLogic({ days });

  return (
    <Card title="Active times">
      <div className={`frequency-container ${small ? "small-card" : ""}`}>
        <SimpleDataCard
          grayBackground
          title="Last 7 days"
          body={data.days_7}
          footer="active times"
          className="frequency-card"
        />
        <SimpleDataCard
          grayBackground
          title="Last month"
          body={data.months_1}
          footer="active times"
          className="frequency-card"
        />
        <SimpleDataCard
          grayBackground
          title="Last 3 months"
          body={data.months_3}
          footer="active times"
          className="frequency-card"
        />
        <SimpleDataCard
          grayBackground
          title="Last 6 months"
          body={data.months_6}
          footer="active times"
          className="frequency-card"
        />
        <SimpleDataCard
          grayBackground
          title="Last year"
          body={data.months_12}
          footer="active times"
          className="frequency-card"
        />
      </div>
    </Card>
  );
};

export default FrequencyCards;
