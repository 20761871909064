import React from "react";
import {
  CCol,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { FrequencyCards } from "components";
import FrequencyCalendar from "./FrequencyCalendar";

const FrequencyModal = ({ showModal, onClose, days }) => {
  return (
    <div>
      <CModal show={showModal} onClose={onClose} size="xl">
        <CModalHeader closeButton>
          <CModalTitle>Mobile app use history</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CRow className="align-items-center">
            <CCol lg={6} className="d-flex justify-content-center mb-3 mb-lg-0">
              <FrequencyCalendar days={days} />
            </CCol>
            <CCol lg={6} className="pl-lg-0">
              <FrequencyCards days={days} />
            </CCol>
          </CRow>
        </CModalBody>
      </CModal>
    </div>
  );
};

export default FrequencyModal;
