import React, { useEffect, useState } from "react";
import "./style.css";

import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import Underline from "@editorjs/underline";
import editorjsHTML from "editorjs-html";
import List from "@editorjs/list";
import Delimiter from "@editorjs/delimiter";
import ErrorAlert from "./../ErrorAlert";

const RichTextEditor = ({
  onChange,
  placeholder,
  // Edit
  initialData = null,
}) => {
  const [isEditorAvailable, setIsEditorAvailable] = useState(null);

  const [data, setData] = useState({
    editorObject: {},
    body: [],
  });

  useEffect(() => {
    onChange(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const newEditor = new EditorJS({
      holder: "editorjs",
      tools: {
        header: {
          class: Header,
          inlineToolbar: true,
          config: {
            placeholder: "Header",
            levels: [1, 2, 3, 4],
            defaultLevel: 2,
          },
        },
        underline: Underline,
        list: {
          class: List,
          inlineToolbar: true,
          config: {
            defaultStyle: "unordered",
          },
        },
        delimiter: Delimiter,
      },
      data: initialData,
      autofocus: false,
      placeholder: placeholder || "Click here to start editing",
      onChange: handleChange,
      logLevel: "ERROR",
    });

    newEditor.isReady
      .then(() => {
        handleClean();
        setIsEditorAvailable(true);
      })
      .catch(() => {
        setIsEditorAvailable(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData]);

  const handleClean = () => {
    const editor = document.getElementById("editorjs");
    const child = editor.getElementsByClassName("codex-editor");
    if (child?.length > 1) child.item(0).remove();
  };

  const handleChange = (api, event) => {
    api.saver
      .save()
      .then((outputData) => {
        setData({
          editorObject: outputData,
          body: convertCleanDataToHTML(outputData),
        });
      })
      .catch((error) => {
        console.dir("Editor.js error", error);
      });
  };

  if (isEditorAvailable === false)
    return (
      <ErrorAlert
        show
        toast
        errorMsg="Sorry, an error occurred while loading the text editor"
      />
    );

  return (
    <div
      id="editorjs"
      style={{
        borderRadius: "0.25rem",
        background: "#fff",
        padding: "1rem",
        border: "1px solid #d8dbe0",
        marginTop: "1rem",
        marginBottom: "1rem",
        paddingLeft: "2.6rem",
      }}
    />
  );
};

export const convertCleanDataToHTML = (data) => {
  const edjsParser = editorjsHTML();
  const htmlArray = edjsParser.parse(data);
  return htmlArray.join("");
};

export default RichTextEditor;
