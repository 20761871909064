import { CInputCheckbox, CLabel } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { getUserFormalityLabel } from "../../utils/strings";
import { getRandomId } from "../../utils/utils";
import ErrorAlert from "../ErrorAlert";

import { useDispatch } from "react-redux";
import { store_setActiveFilters } from "store/filters.store";

const initState = {
  thriver: false,
  formalCaregiver: false,
  informalCaregiver: false,
};

const IntendedUserInput = ({
  onChange,
  initUsers = [],
  showError = false,
  showLabel = true,
}) => {
  const [checkedUsers, setCheckedUsers] = useState(initState);

  useEffect(() => {
    if (initUsers.length > 0) {
      let newUsers = { ...initState };
      initUsers.forEach((user) => {
        if (user in newUsers) newUsers[user] = true;
      });
      setCheckedUsers(newUsers);
    }
  }, [initUsers]);

  const dispatch = useDispatch();

  const onChangeHandler = (e) => {
    const newUsers = { ...checkedUsers, [e.target.name]: e.target.checked };
    let filtersCount = 0;
    if (newUsers.thriver) filtersCount++;
    if (newUsers.formalCaregiver) filtersCount++;
    if (newUsers.informalCaregiver) filtersCount++;
    dispatch(store_setActiveFilters(filtersCount));

    let users = [];
    for (const user in newUsers) if (newUsers[user]) users.push(user);
    onChange(users);
    setCheckedUsers(newUsers);
  };

  return (
    <>
      {showLabel && <p className="mt-3">For who is this content? *</p>}
      <ErrorAlert toast errorMsg="Select at least 1 user" show={showError} />
      <div className="my-2 d-flex flex-wrap gap-20 pb-2 pt-3 px-3 d-flex flex-row">
        {Object.keys(checkedUsers).map((user) => {
          const id = getRandomId();
          return (
            <span key={id}>
              <CInputCheckbox
                id={id}
                name={user}
                checked={checkedUsers[user]}
                onChange={onChangeHandler}
                className="mx-0 pointer"
              />
              <CLabel htmlFor={id} className="ml-3 pointer">
                {getUserFormalityLabel(user)}
              </CLabel>
            </span>
          );
        })}
      </div>
    </>
  );
};

export default IntendedUserInput;
