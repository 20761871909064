import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showSidebar: true,
};

export const slice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    store_setShowSidebar: (state, action) => {
      state.showSidebar = action.payload;
    },
  },
});

export const store_getShowSidebar = (store) => store.settings.showSidebar;

export const { store_setShowSidebar } = slice.actions;
export default slice.reducer;
