import React from "react";
import {
  CCreateElement,
  CSidebar,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarNavItem,
  CSidebarMinimizer,
  CSidebarBrand,
} from "@coreui/react";

import logo from "assets/icons/logo-dark.svg";
import logo_small from "assets/icons/logo-dark-small.svg";
import SidebarLogic from "./Sidebar.logic";
import "./Sidebar.scss";

const TheSidebar = () => {
  const { showSidebar, sidebarItems, onShowChange } = SidebarLogic();

  return (
    <CSidebar
      className="my_sidebar"
      onShowChange={onShowChange}
      show={showSidebar}
    >
      {/* Thriving logo */}
      <CSidebarBrand className="brand" to="/">
        <img
          alt="logo"
          src={logo_small}
          className="c-sidebar-brand-minimized"
        />
        <img alt="logo" src={logo} className="c-sidebar-brand-full" />
      </CSidebarBrand>

      {/* Items of the side menu */}
      <CSidebarNav className="sidebar_nav">
        <CCreateElement
          items={sidebarItems}
          components={{
            CSidebarNavDivider,
            CSidebarNavItem,
            CSidebarNavTitle,
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer />
    </CSidebar>
  );
};

export default React.memo(TheSidebar);
