import CIcon from "@coreui/icons-react";
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CRow,
} from "@coreui/react";

import { InputGroup, Loading, ErrorAlert } from "components";
import LoginFormLogic from "./LoginForm.logic";

import { Colors } from "utils";

const LoginForm = () => {
  const { expired, error, isLoading, errorMsg, handleLogin, handleChange } =
    LoginFormLogic();

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={handleLogin}>
                    <h1>Login</h1>

                    {expired ? (
                      <CAlert className="mt-3" color="info">
                        Your session has expired, please login again
                      </CAlert>
                    ) : (
                      <p className="text-muted">Sign In to your account</p>
                    )}

                    <InputGroup prepend={<CIcon name="cil-user" />}>
                      <CInput
                        type="text"
                        placeholder="Username"
                        autoComplete="username"
                        name="username"
                        onChange={handleChange}
                      />
                    </InputGroup>

                    <InputGroup prepend={<CIcon name="cil-lock-locked" />}>
                      <CInput
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                        name="password"
                        onChange={handleChange}
                      />
                    </InputGroup>

                    <Loading show={isLoading} />

                    <ErrorAlert errorMsg={errorMsg} show={error} margin />
                    <CButton type="submit" color="primary" disabled={isLoading}>
                      Login
                    </CButton>
                  </CForm>
                </CCardBody>
              </CCard>

              <CCard
                className="text-white py-5 d-md-down-none"
                style={{ width: "44%", background: Colors._blue }}
              >
                <CCardBody className="text-center">
                  <CIcon
                    className="c-sidebar-brand-full"
                    name="logo"
                    alt="Thrive Community: Senior Care"
                    height={85}
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "53%",
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default LoginForm;
