import { removeLocalStore } from "../../utils/localStorage";

export const logout = () => {
  removeLocalStore();
  window.location.replace("/login");
};

export const logoutExpired = () => {
  removeLocalStore();
  window.location.replace("/login?expired=true");
};
