const Constants = (function () {
  const userTypes = {
    thriver: "thriver",
    caregiver: "caregiver",
    careHome: "carehome",
  };

  const dashboardRoles = {
    caregiver: "careProviderAdmin",
    careHome: "careHomeAdmin",
    admin: "superAdmin",
    admin_2: "admin",
  };

  const userTableTypes = {
    careHomes: "carehomes",
    users: "users",
    caregivers: "caregivers",
  };

  const moods = {
    happy: "happy",
    normal: "normal",
    sad: "sad",
  };

  return { userTypes, dashboardRoles, userTableTypes, moods };
})();

export default Constants;
