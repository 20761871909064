import { useState } from "react";
import { useEffect } from "react";

const NameEmailFilterLogic = ({ users, onFilterChange }) => {
  const [value, setValue] = useState("");
  const onChange = (e) => setValue(e.target.value);

  useEffect(() => {
    if (!value || !users) {
      // return the original list of users
      onFilterChange(users);
      return;
    }
    try {
      const reg = new RegExp(value, "gi");
      const result = users.filter((v) => reg.test(v.name) || reg.test(v.email));
      onFilterChange(result);
    } catch (error) {
      onFilterChange(users);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, users]);

  const onReset = () => setValue("");

  return { value, onChange, onReset };
};

export default NameEmailFilterLogic;
