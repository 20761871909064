import FilterInput from "./FilterInput";
import { ButtonsContainer } from "components";
import { Role } from "utils";
import UsersFilterLogic from "./UsersFilter.logic";

export default function UsersFilter({
  allUsers,
  resetSelectedUser,
  onChangeFilters,
  tableType,
}) {
  const { filters, changeFilter, resetFilters } = UsersFilterLogic({
    allUsers,
    resetSelectedUser,
    onChangeFilters,
  });

  return (
    <>
      <div className="pb-2 pt-3 px-3 d-flex flex-column">
        {FilterInput({
          value: filters.emailName,
          name: "emailName",
          icon: "cil-user",
          label: Role.adminAccess() ? "Identifier" : "Name or email",
          onChange: changeFilter,
        })}

        {FilterInput({
          value: filters.ageCategory,
          name: "ageCategory",
          label: "Age category",
          options: [
            { value: "any", label: "Any" },
            { value: "<50", label: "<50" },
            { value: "51-60", label: "51-60" },
            { value: "61-70", label: "61-70" },
            { value: "71-80", label: "71-80" },
            { value: "81>", label: "81>" },
          ],
          onChange: changeFilter,
        })}

        {FilterInput({
          value: filters.gender,
          name: "gender",
          label: "Gender",
          options: [
            { value: "any", label: "Any" },
            { value: "m", label: "Male" },
            { value: "f", label: "Female" },
            { value: "u", label: "I'd rather not say" },
          ],
          onChange: changeFilter,
        })}
        {tableType === "users" &&
          FilterInput({
            value: filters.type,
            name: "type",
            label: "User type",
            // TODO use constants
            options: [
              { value: "any", label: "Any" },
              { value: "caregiver", label: "Caregiver" },
              { value: "thriver", label: "Thriver" },
              { value: "carehome", label: "Carehome" },
            ],
            onChange: changeFilter,
          })}
        {FilterInput({
          value: filters.status,
          name: "status",
          label: "User status",
          options: [
            { value: "any", label: "Any" },
            { value: "pending", label: "Pending" },
            { value: "active", label: "Active" },
          ],
          onChange: changeFilter,
        })}

        {/* TODO use constants userTableTypes */}
        {tableType === "carehomes" &&
          FilterInput({
            value: filters.dashboardType,
            name: "dashboardType",
            label: "Dashboard type",
            options: [
              { value: "any", label: "Any" },
              { value: "superAdmin", label: "Super Admin" },
              { value: "careHomeAdmin", label: "CareHome Admin" },
            ],
            onChange: changeFilter,
          })}
      </div>

      <ButtonsContainer noPadding>
        <span
          className="link-dark pointer p-3 pt-0 mb-2"
          onClick={resetFilters}
        >
          Reset filters
        </span>
      </ButtonsContainer>
    </>
  );
}
