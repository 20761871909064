import { createSlice } from "@reduxjs/toolkit";

export const sliceName = "learning";

const initialState = {
  learningJourneyData: {},
};

export const ljSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    store_setLearningJourneyData: (state, action) => {
      state.learningJourneyData = action.payload;
    },
    store_setLearningJourneySteps: (state, action) => {
      state.learningJourneyData.steps = action.payload;
    },
  },
});

export const store_getLJData = (state) => state.alerts;

export const { store_setLearningJourneyData, store_setLearningJourneySteps } =
  ljSlice.actions;
export default ljSlice.reducer;
