import { configureStore } from "@reduxjs/toolkit";

import lj_reducer, {
  sliceName as LJName,
} from "../pages/LearningJourneys/LearningJourneys.store";
import auth_reducer from "../pages/Auth/Auth.store";
import dashboard_reducer, { sliceName as dashboardName,} from "../pages/Dashboards/Dashboard.store";
import settings_reducer from "./settings.store";
import filters_reducer from "./filters.store";
import zinnia_reducer from "../pages/ZinniaResources/Zinnia.store";

import { carePlan_reducer, sliceName as carePlan } from "pages/CarePlan";

export const store = configureStore({
  reducer: {
    settings: settings_reducer,
    auth: auth_reducer,
    [dashboardName]: dashboard_reducer,
    [LJName]: lj_reducer,
    filters: filters_reducer,
    [carePlan]: carePlan_reducer,
    zinnia: zinnia_reducer,
  },
});
