import React, { useEffect, useState } from "react";
import { CCol, CRow } from "@coreui/react";
import FrequencyCalendar from "./FrequencyCalendar";
import { FrequencyCards } from "components";

const CalendarRow = ({ thrivers }) => {
  const [days, setDays] = useState([]);

  useEffect(() => {
    const newDays = [];
    thrivers.forEach((thriver) =>
      thriver?.dateCheckList?.forEach((date) => newDays.push(date))
    );
    setDays(newDays);
  }, [thrivers]);

  return (
    <CRow className="align-items-center">
      <CCol sm="12" lg="5" className="mb-3 d-flex justify-content-center">
        <FrequencyCalendar days={days} countDays />
      </CCol>
      <CCol sm="12" lg="7" className="pl-lg-0 ml-lg-0">
        <FrequencyCards days={days} />
      </CCol>
    </CRow>
  );
};

export default CalendarRow;
