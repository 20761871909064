import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  caregiver: {},
  thriver: {},
};

export const sliceName = "carePlan";

export const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    set_carePlan_caregiver: (state, action) => {
      state.caregiver = action.payload;
    },
    set_carePlan_thriver: (state, action) => {
      state.thriver = action.payload;
    },
    reset_carePlan_users: (state) => {
      state.thriver = {};
      state.caregiver = {};
    },
  },
});

export const store_carePlan_caregiver = (store) => store[sliceName].caregiver;
export const store_carePlan_thriver = (store) => store[sliceName].thriver;

export const {
  set_carePlan_caregiver,
  set_carePlan_thriver,
  reset_carePlan_users,
} = slice.actions;
export default slice.reducer;
