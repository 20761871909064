import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
};

export const slice = createSlice({
  name: "resourceDetails",
  initialState,
  reducers: {
    store_setResourceDetails: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const store_getResourceDetails = (store) => store.resourceDetails.data;

export const { store_setResourceDetails } = slice.actions;
export default slice.reducer;
