import React from "react";
import { CCard } from "@coreui/react";
import "./SimpleDataCard.scss";

const SimpleDataCard = ({
  title,
  footer,
  body,
  grayBackground,
  className = "",
}) => {
  return (
    <CCard
      className={`data-card ${
        grayBackground ? "gray-background" : ""
      } m-1 text-center ${className} `}
    >
      <span className="data-card-title">{title}</span>
      <span className="data-card-body">{body}</span>
      <span className="data-card-footer">{footer}</span>
    </CCard>
  );
};

export default SimpleDataCard;
