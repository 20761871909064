import React from "react";
import "./Table.scss";

import arrow from "assets/icons/arrow.svg";

const Pagination = ({ activePage, onActivePageChange, pages }) => {
  const onChange = (e) => {
    const value = parseInt(e.target.value);
    if (isNaN(value)) return;
    changePage(value);
  };

  const changePage = (value) => {
    if (value > pages) return onActivePageChange(pages);
    if (value < 1) return onActivePageChange(1);
    return onActivePageChange(value);
  };

  return (
    <div className="custom_pagination">
      <button
        disabled={activePage <= 1}
        className={activePage <= 1 ? "disabled" : ""}
        onClick={() => changePage(activePage - 1)}
      >
        <img alt="<" src={arrow} />
      </button>
      <input value={activePage} onChange={onChange} />
      <button
        disabled={activePage >= pages}
        className={activePage >= pages ? "disabled" : ""}
        onClick={() => changePage(activePage + 1)}
      >
        <img alt=">" src={arrow} />
      </button>
    </div>
  );
};

export default Pagination;
