import { useState, useRef, useEffect } from "react";
import { CInput, CInputGroupText, CLabel } from "@coreui/react";
import ReactPlayer from "react-player";
import CIcon from "@coreui/icons-react";
import ErrorAlert from "../ErrorAlert";
import InputGroup from "./InputGroup";

export default function MediaInput({
  initMediaValue = null,
  initMediaType = null,
  handleMediaChange = () => null,
  isItem = true,
  reset = false,
  parentError = false,
  allowSelectMedia = true,
  width = 300,
  height = 200,
  margin = true,
}) {
  const mediaInput = useRef(null);
  const [mediaState, setMediaState] = useState({
    type: null,
    value: "",
    error: false,
  });

  const [errorMsg, setErrorMsg] = useState("");

  const resetMedia = () => {
    if (mediaInput?.current) mediaInput.current.value = "";
    setMediaState({
      type: initMediaType,
      value: initMediaValue,
      error: false,
    });
  };

  useEffect(() => {
    resetMedia();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initMediaType, initMediaValue, reset]);

  // Alert changes to parent

  useEffect(() => {
    if (!mediaState.error) setErrorMsg("");
    handleMediaChange(mediaState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaState]);

  // Handlers

  const handleError = () => {
    if (mediaState.type === "image_link") {
      setErrorMsg("Invalid image link");
      setMediaState({ ...mediaState, error: true });
      //
    } else if (mediaState.type === "video_link") {
      setErrorMsg("Invalid video link");
      setMediaState({ ...mediaState, error: true });
      //
    } else if (
      mediaState.type === "image_file" ||
      mediaState.type === "video_file"
    ) {
      mediaInput.current.value = "";
      setPreview(null);
      setErrorMsg("Invalid file");
      setMediaState({ ...mediaState, error: true });
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "imageLinkInput") {
      if (e.target.value.substr(0, 5) === "data:") {
        setMediaState({ ...mediaState, error: true });
        return;
      }
      setMediaState({
        ...mediaState,
        type: "image_link",
        value: e.target.value,
        error: false,
      });
      if (mediaInput?.current) mediaInput.current.value = "";
    } else if (e.target.name === "videoLinkInput") {
      let videoLink = e.target.value;
      setMediaState({
        ...mediaState,
        type: "video_link",
        value: videoLink,
        error: false,
      });
      mediaInput.current.value = "";
    } else if (e.target.name === "fileSelectInput") {
      let file = e.target.files[0];

      // TODO check file size

      if (file.type.includes("image")) {
        setMediaState({
          ...mediaState,
          type: "image_file",
          value: file,
          error: false,
        });
      } else if (file.type.includes("video")) {
        setMediaState({
          ...mediaState,
          type: "video_file",
          value: file,
          error: false,
        });
      } else {
        setErrorMsg("Invalid file");
        setMediaState({ ...mediaState, type: null, value: "", error: true });
      }
    }
  };

  // Media preview

  const [preview, setPreview] = useState(null);

  const createPreview = (image) => {
    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  };

  useEffect(() => {
    if (mediaState.type === "image_link" || mediaState.type === "video_link") {
      setPreview(null);
    } else if (
      mediaState.type === "image_file" ||
      mediaState.type === "image_video"
    ) {
      try {
        createPreview(mediaState.value);
      } catch (error) {}
    }
  }, [mediaState.value, mediaState.type]);

  // Inputs

  const InputImageLink = () => {
    const inputValue = mediaState.type === "image_link" ? mediaState.value : "";

    return (
      <InputGroup
        prepend={<CIcon name="cil-camera" />}
        append={
          <CInputGroupText onClick={resetMedia} className="hover-blue cursor">
            <CIcon name="cil-reload" />
          </CInputGroupText>
        }
      >
        <CInput
          type="text"
          placeholder="Paste an image link"
          name="imageLinkInput"
          value={inputValue}
          onChange={handleChange}
        />
      </InputGroup>
    );
  };

  const InputVideoLink = () => {
    if (!isItem) return null;
    const inputValue = mediaState.type === "video_link" ? mediaState.value : "";
    return (
      <InputGroup
        prepend={<CIcon name="cil-video" />}
        append={
          <CInputGroupText onClick={resetMedia} className="hover-blue cursor">
            <CIcon name="cil-reload" />
          </CInputGroupText>
        }
      >
        <CInput
          type="text"
          placeholder="Or... Paste a video link"
          name="videoLinkInput"
          value={inputValue}
          onChange={handleChange}
        />
      </InputGroup>
    );
  };

  const InputMedia = () => {
    const accept = isItem
      ? ".jpg, .jpeg, .png, .mp4, .wmv"
      : ".jpg, .jpeg, .png";
    return !allowSelectMedia ? null : (
      <span>
        Or...
        <input
          style={{ margin: "5px 0 15px 15px", maxWidth: "90%" }}
          ref={mediaInput}
          type="file"
          name="fileSelectInput"
          onChange={handleChange}
          accept={accept}
        />
      </span>
    );
  };

  // Media display

  const displayWidth = width;
  const displayHeight = height;

  const imgStyles = {
    display: `${errorMsg ? "none" : "block"}`,
    height: `${displayHeight}px`,
    width: `${displayWidth}px`,
    objectFit: "cover",
    objectPosition: "center",
    marginTop: "1rem",
    borderRadius: "0.25rem",
  };

  const MediaDisplay = () => {
    return !mediaState.error ? (
      <div>
        {mediaState.type === "image_link" && (
          <div>
            <img
              style={imgStyles}
              onError={handleError}
              alt=""
              src={mediaState.value}
            />
          </div>
        )}
        {mediaState.type === "image_file" && (
          <div>
            <img style={imgStyles} onError={handleError} alt="" src={preview} />
          </div>
        )}
        {(mediaState.type === "video_link" ||
          mediaState.type === "video_file") &&
          !mediaState.error && (
            <ReactPlayer
              url={
                mediaState.type === "video_link" ? mediaState.value : preview
              }
              controls
              width={displayWidth}
              height={displayHeight}
              onError={handleError}
            />
          )}
      </div>
    ) : null;
  };

  return (
    <div>
      <CLabel className={margin ? "mt-3" : ""}>Media *</CLabel>
      <ErrorAlert errorMsg={errorMsg} show={errorMsg} margin />
      <ErrorAlert
        errorMsg="Please add a valid media link or file"
        show={parentError}
        margin
        toast
      />

      {InputImageLink()}
      {InputVideoLink()}
      {InputMedia()}

      <MediaDisplay />
    </div>
  );
}
