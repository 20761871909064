import { useMutation, useQuery } from "react-query";
import { useDispatch } from "react-redux";

import { loginService, getInfoService } from "./Auth.service";
import { store_setAuthData } from "./Auth.store";

export const useAuthMutation = () => {
  return useMutation((req) => loginService(req), {
    retry: false,
  });
};

export const useGetInfoQuery = () => {
  const dispatch = useDispatch();
  return useQuery("auth", getInfoService, {
    enabled: false,
    onSuccess: (data) => {
      dispatch(store_setAuthData(data));
    },
  });
};
