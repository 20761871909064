import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  store_cleanActiveFilter,
  store_setActiveFilters,
} from "store/filters.store";

const initFilters = {
  ageCategory: "any",
  gender: "any",
};

const HealthFilterLogic = ({
  allUsers = [],
  onChangeFilters = () => null,
}) => {
  const [filters, setFilters] = useState(initFilters);
  const resetFilters = () => setFilters(initFilters);

  useEffect(() => {
    resetFilters();
  }, [allUsers]);

  const changeFilter = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  // Filtering functions

  const filterAge = (age) => {
    let ageFilterResult = false;
    if (filters.ageCategory === "any") return true;

    const userAge = parseInt(age);
    if (userAge)
      switch (filters.ageCategory) {
        case "<50":
          ageFilterResult = userAge <= 50;
          break;
        case "51-60":
          ageFilterResult = userAge >= 51 && userAge <= 60;
          break;
        case "61-70":
          ageFilterResult = userAge >= 61 && userAge <= 70;
          break;
        case "71-80":
          ageFilterResult = userAge >= 71 && userAge <= 80;
          break;
        case "81>":
          ageFilterResult = userAge >= 81;
          break;
        default:
          break;
      }

    return ageFilterResult;
  };

  const filterGender = (gender) =>
    filters.gender === "any" ? true : gender === filters.gender;

  // Check filters

  const dispatch = useDispatch();
  const modifyNumberOfFilters = () => {
    let filtersCont = 0;

    if (filters.ageCategory !== "any") filtersCont++;
    if (filters.gender !== "any") filtersCont++;

    dispatch(store_setActiveFilters(filtersCont));
  };

  useEffect(() => {
    const newUsers = allUsers.filter(
      (item) =>
        filterAge(item?.age) &&
        filterGender(item?.gender) 
    );
    modifyNumberOfFilters();
    onChangeFilters(newUsers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    return () => dispatch(store_cleanActiveFilter());
  }, [dispatch]);

  return { filters, changeFilter, resetFilters };
};

export default HealthFilterLogic;
