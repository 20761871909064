const { Colors } = require("utils");

const Graph = (function () {
  function getOptions({
    yTitle = "",
    hideLegends = false,
    hideScales = false,
    thinBars = true,
    hideDatalabels = true,
    legendsAtTop = false,
    thinLines = false,
    curved = false,
    ...rest
  }) {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      animations: {
        radius: {
          duration: 300,
          easing: "easeInBounce",
        },
      },
      plugins: {
        legend: {
          display: !hideLegends,
          align: legendsAtTop ? "end" : "start",
          position: legendsAtTop ? "top" : "bottom",
          labels: {
            font: { family: "Poppins, sans-serif" },
            usePointStyle: true,
            boxWidth: 7,
            boxHeight: 7,
            padding: 14,
          },
        },
        datalabels: {
          color: Colors._pureWhite,
          font: { family: "Poppins, sans-serif" },
          anchor: "start",
          align: "end",
          display: function (context) {
            if (hideDatalabels) return false;
            const value = context.dataset.data[context.dataIndex];
            return value !== 0;
          },
        },
        tooltip: {
          backgroundColor: Colors._pureWhite,
          borderColor: "rgba(0,0,0,0.1)",
          borderWidth: 1,
          titleColor: Colors._pureBlack,
          bodyColor: Colors._pureBlack,
          displayColors: false,
          titleFont: {
            size: 16,
            family: "Poppins, sans-serif",
          },
          bodyFont: {
            size: 11,
            family: "Poppins, sans-serif",
          },
          callbacks: {
            title: function (context) {
              return (
                context?.formattedValue ||
                (context?.length && context[0]?.formattedValue) ||
                ""
              );
            },
            label: function (context) {
              return (
                context?.label || (context?.length && context[0]?.label) || ""
              );
            },
          },
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          title: {
            display: yTitle !== "",
            text: yTitle || "",
          },
        },
      },
      ...rest,
    };
    if (thinBars) options.maxBarThickness = 45;
    if (hideScales) options.scales = {};
    if (thinLines) options.borderWidth = 2;
    if (curved) options.tension = 0.5;
    return options;
  }

  return { getOptions };
})();

export default Graph;
