import CIcon from "@coreui/icons-react";
import React from "react";

const DayElement = ({ date }) => {
  return (
    <div className="d-flex flex-column align-items-center">
      {date?.log ? (
        date?.total ? (
          <div
            style={{
              border: "1.5px solid #2187fb",
              width: "32px",
              height: "32px",
              borderRadius: "50%",
              color: "#2187fb",
            }}
            className="d-flex align-items-center justify-content-center total-checks"
          >
            {date?.total}
          </div>
        ) : (
          <>
            <CIcon size="lg" className="color-blue" name="cil-check-circle" />
            <span>{date?.day}</span>
          </>
        )
      ) : (
        <>
          <CIcon size="lg" name="cil-x-circle" />
          <span>{date?.day}</span>
        </>
      )}
    </div>
  );
};

export default DayElement;
