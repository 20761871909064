import React, { useState } from "react";
import CIcon from "@coreui/icons-react";
import { CButton } from "@coreui/react";

import { ConfirmDialog } from "components";

import "./Buttons.scss";

const DeleteButton = ({
  onDelete = () => null,
  message = "",
  showBorder = false,
}) => {
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  const defaultMessage =
    message || `Are you sure you want to delete this item?`;

  return (
    <>
      <CButton
        onClick={() => setIsDialogVisible(true)}
        className={`buttons deleteButton ${showBorder ? "bordered" : ""}`}
      >
        <CIcon name="cil-Trash" />
        <span>DELETE</span>
      </CButton>
      <ConfirmDialog
        visible={isDialogVisible}
        handleClose={() => setIsDialogVisible(false)}
        handleAccept={onDelete}
        message={defaultMessage}
      />
    </>
  );
};

export default DeleteButton;
