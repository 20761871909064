import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import { Alert } from "utils";
import { getErrorMessage } from "utils/utils";

const dev = process.env.REACT_APP_ENV === "dev";

const queryOptions = {
  defaultOptions: {
    queries: {
      staleTime: dev ? 1000 * 120 : 0,
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
  queryCache: new QueryCache({
    onError: (error) => Alert.error(getErrorMessage(error)),
  }),
  mutationCache: new MutationCache({
    onError: (error) => Alert.error(getErrorMessage(error)),
  }),
};

const queryClient = new QueryClient(queryOptions);

const QueryWrapper = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {dev && (
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      )}
    </QueryClientProvider>
  );
};

export default QueryWrapper;
