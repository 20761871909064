import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
};

export const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    store_setAuthData: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const store_getAuthUser = (store) => store.auth.user;

export const { store_setAuthData } = slice.actions;
export default slice.reducer;
