import CIcon from "@coreui/icons-react";
import { CLabel, CSelect } from "@coreui/react";
import React, { useEffect, useState } from "react";

import ErrorAlert from "../ErrorAlert";
import { useGetCountriesCatalogQuery } from "../../pages/IoT/IoT.query";
import { Colors } from "utils";

const LocationSelect = ({
  newContentLocations = [],
  onLocationsChange = () => null,
  showError = false,
}) => {
  const locationsList = ["US", "UK"];
  const [options, setOptions] = useState([]);

  const { data } = useGetCountriesCatalogQuery();
  useEffect(() => {
    if (data?.countries?.length > 0) {
      setOptions(data?.countries?.map((x) => x?.countryCode));
    }
  }, [data]);

  const removeLocation = (locationToDelete) => {
    const newLocations =
      newContentLocations.filter((x) => x !== locationToDelete) || [];
    onLocationsChange(newLocations);
  };

  const addLocation = (e) => {
    if (e.target.value === "all") {
      onLocationsChange(options);
    } else {
      let newLocations = [...(newContentLocations || [])];
      newLocations.push(e.target.value);
      onLocationsChange(newLocations);
    }
  };

  return (
    <div>
      <CLabel className="mt-3" htmlFor="f-location">
        Location of the content *
      </CLabel>
      <ErrorAlert
        errorMsg="Select at least 1 location"
        toast
        show={showError}
        className="mb-1"
      />
      <div className="d-flex gap-15 mb-2" style={{ overflow: "auto" }}>
        {newContentLocations?.length === options?.length ||
        newContentLocations?.length > 20 ? (
          <h5 className="mt-2" style={{ color: Colors._blue }}>
            All locations selected
          </h5>
        ) : (
          newContentLocations?.map((location) => (
            <span
              className="hover-blue pointer"
              onClick={() => removeLocation(location)}
              style={{ borderRadius: "5px", padding: "2px 8px" }}
              key={location}
            >
              <strong>{location}</strong>{" "}
              <CIcon name="cil-X" className="pb-1" />
            </span>
          ))
        )}
      </div>

      {newContentLocations?.length > 0 && (
        <span
          className="hover-blue pointer"
          onClick={() => onLocationsChange([])}
          style={{ borderRadius: "5px", padding: "2px 8px" }}
        >
          <strong>Delete selected locations</strong>
        </span>
      )}

      <CSelect
        style={{ width: "220px" }}
        id="f-location"
        value={0}
        onChange={addLocation}
        className="my-2"
        disabled={newContentLocations?.length === options?.length}
      >
        <option value={0} disabled hidden>
          Select 1 or more locations
        </option>
        <option
          hidden={newContentLocations?.length === options.length}
          value={"all"}
        >
          All locations
        </option>
        {locationsList.map((location, index) => (
          <option
            key={index}
            value={location}
            hidden={newContentLocations?.includes(location)}
          >
            {location}
          </option>
        ))}
      </CSelect>
    </div>
  );
};

export default LocationSelect;
