import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";

import { AuthWrapper, ErrorWrapper } from "wrappers";
import { Layout } from "containers";
import { icons } from "assets/icons";
import { Spinner } from "components";

React.icons = icons;

const LoginView = React.lazy(() => import("./pages/Auth/views/Login.view"));
const ErrorView = React.lazy(() => import("./pages/Home/UI/Page500.view"));

function App() {
  return (
    <Suspense fallback={<Spinner grow />}>
      <Switch>
        <Route
          path="/login"
          name="Login"
          render={(props) => <LoginView {...props} />}
        />
        <Route path="/500" name="Error" render={() => <ErrorView />} />
        <Route
          path="/"
          name="Home"
          render={(props) => (
            <AuthWrapper>
              <ErrorWrapper>
                <Layout {...props} />
              </ErrorWrapper>
            </AuthWrapper>
          )}
        />
        <Route path="*" render={() => <NoMatch />} />
      </Switch>
    </Suspense>
  );
}

const NoMatch = () => {
  return <p>There's nothing here: 404!</p>;
};

export default App;
