import { useEffect } from "react";
import { Prompt } from "react-router-dom";

export function PreventWindowUnload({ preventDefault, message }) {
  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue =
      message || "You have unsaved changes. Are you sure you want to leave";
  };

  useEffect(() => {
    if (!preventDefault) return;

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preventDefault]);

  return (
    <Prompt
      when={preventDefault}
      message={
        message || "You have unsaved changes. Are you sure you want to leave?"
      }
    />
  );
}
