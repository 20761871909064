import React, { useMemo } from "react";
import { CButton } from "@coreui/react";
import { CSVLink } from "react-csv";
import CIcon from "@coreui/icons-react";

import { getUtcFormattedDate, difBetweenDates } from "utils/dates";
import { Constants } from "utils";

const DownloadUsers = ({ users = [], tableType }) => {
  const headers = useMemo(() => {
    if (tableType === Constants.userTableTypes.users) {
      return [
        { key: "name", label: "Name" },
        { key: "email", label: "Email" },
        { key: "status", label: "Status" },
        { key: "type", label: "User type" },
        { key: "numbersOfCircles", label: "Care circles" },
        { key: "registrationDate", label: "Registration" },
        { key: "firstLogin", label: "App first login" },
        { key: "lastLogin", label: "App last login" },
        { key: "daysSinceActivity", label: "Last app activity (days)" },
      ];
    }

    return [
      { key: "name", label: "Name" },
      { key: "email", label: "Email" },
      { key: "dob", label: "Date Of Birth" },
      { key: "gender", label: "Gender" },
      { key: "type", label: "Type" },
      { key: "status", label: "Status" },
    ];
  }, [tableType]);

  const csvData = useMemo(() => {
    if (!users?.length > 0) return [];

    if (tableType === Constants.userTableTypes.users) {
      return users?.map((u) => {
        const {
          name,
          email,
          status,
          type,
          numbersOfCircles,
          registrationDate,
          dateCheckList,
        } = u;

        const checkInsLength = dateCheckList?.length || 0;

        const firstLogin = checkInsLength > 0 ? dateCheckList[0] : "";
        const lastLogin =
          checkInsLength > 0 ? dateCheckList[checkInsLength - 1] : "";

        const daysSinceActivity =
          firstLogin && lastLogin ? difBetweenDates(lastLogin, new Date()) : "";

        return {
          name,
          email,
          status,
          type,
          numbersOfCircles,
          registrationDate: getUtcFormattedDate(registrationDate),
          firstLogin,
          lastLogin,
          daysSinceActivity,
        };
      });
    }

    return users?.map((x) => ({
      name: x.name,
      email: x.email,
      dob: x.dob ? getUtcFormattedDate(x.dob) : "",
      gender: x.gender,
      type: x.type,
      status: x.status,
    }));
  }, [users, tableType]);

  return (
    <div>
      <CSVLink data={csvData} headers={headers} separator=",">
        <CButton
          size="sm"
          title="Download data"
          color="primary"
          className="h-100"
        >
          <CIcon style={{ color: "#fff" }} name="cil-data-transfer-down" />
        </CButton>
      </CSVLink>
    </div>
  );
};

export default DownloadUsers;
