import moment from "moment";

export const systemDateFormat = "YYYY-MM-DD";

export const getLastCheckin = (user) => {
  const checks = user?.dateCheckList ? [...user?.dateCheckList] : [];
  checks.sort((e1, e2) => moment(e2) - moment(e1));
  return checks[0] || "";
};

export const getAge = (dob) =>
  dob ? moment().diff(moment.utc(dob)._i, "years", false) : null;

export const getUtcFormattedDate = (date) => {
  if (!date) return "";
  try {
    return moment.utc(date).format(systemDateFormat);
  } catch (error) {
    return date || "";
  }
};

export const getFormattedDate = (date) => {
  try {
    return moment(date).format(systemDateFormat);
  } catch (error) {
    return date || "";
  }
};

export const getFormatedDob = (dob) => {
  if (!dob) return dob;
  try {
    return moment.utc(dob).startOf("day").toISOString();
  } catch (error) {
    return dob;
  }
};

export const difBetweenDates = (firstDate, secondDate) =>
  moment.utc(secondDate).diff(moment.utc(firstDate), "days") + 1;

export const today = () => moment.utc().format(systemDateFormat);

export const getStringDate = (date) =>
  moment.utc(date).format("ddd DD MMMM YYYY");

export const getTimeFromDate = (date) => moment.utc(date).format("hh:mm a");

const Dates = (function () {
  const systemDateFormat = "YYYY-MM-DD";

  const format = (date, type = 0, utc = true) => {
    if (!date) return "";
    let format = systemDateFormat;
    if (type === 1) format = "ddd DD MMMM YYYY";
    else if (type === 2) format = "D MMM YYYY hh:mma";
    else if (type === 3) format = "hh:mm a";
    else if (type === 4) format = "HH:mm";
    try {
      return utc
        ? moment.utc(date).format(format)
        : moment(date).format(format);
    } catch (error) {
      return date;
    }
  };

  const dif = (date1, date2, unit = "days") =>
    moment(date2).diff(moment(date1), unit);

  return { format, dif };
})();

export { Dates };
