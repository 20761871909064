import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getNavigation } from "navigation/menu";
import {
  store_getShowSidebar,
  store_setShowSidebar,
} from "store/settings.store";
import { getLocalStorageLocation } from "utils/localStorage";

const TheSidebarLogic = () => {
  const dispatch = useDispatch();
  const location = getLocalStorageLocation();
  const showSidebar = useSelector(store_getShowSidebar);

  const sidebarItems = useMemo(() => {
    return getNavigation({ location });
  }, [location]);

  // Controls the view of the sidebar
  const onShowChange = (val) => dispatch(store_setShowSidebar(val));

  return { sidebarItems, showSidebar, onShowChange };
};

export default TheSidebarLogic;
