import { Link } from "react-router-dom";

const ThriverProfileLink = ({
  user,
  underline = false,
  showIfNotThriver = false,
  children,
}) => {
  const identifier = user?.identifier || user?.id;
  const { type } = user;

  return type !== "thriver" ? (
    showIfNotThriver ? (
      children
    ) : null
  ) : (
    <Link
      title="Thriver profile"
      to={{
        pathname: `/user-preview`,
        state: { userId: identifier },
      }}
      className={`link-dark${underline ? "-underlined" : ""}`}
      style={{ width: "auto", display: "inline-block" }}
    >
      {children}
    </Link>
  );
};

export default ThriverProfileLink;
