import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

const production = ["production"].includes(process.env.REACT_APP_ENV);

const BugsnagWrapper = ({ children }) => {
  if (production) {
    Bugsnag.start({
      apiKey: process.env.REACT_APP_BUGSNAG_APIKEY,
      plugins: [new BugsnagPluginReact()],
      releaseStage: process.env.REACT_APP_ENV,
    });
    const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
    return (
      <ErrorBoundary onError={(event, callback) => Bugsnag.notify(event)}>
        {children}
      </ErrorBoundary>
    );
  } else return <>{children}</>;
};

export default BugsnagWrapper;
