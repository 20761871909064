import { useEffect } from "react";
import { CAlert } from "@coreui/react";

import { Alert } from "utils";

const ErrorAlert = ({
  errorMsg,
  show = true,
  margin,
  toast = false,
  warning = false,
  ...props
}) => {
  //
  useEffect(() => {
    if (toast && show) Alert.error(errorMsg);
  }, [show, toast, errorMsg]);

  return !show ? null : (
    <CAlert
      color={warning ? "warning" : "danger"}
      className={margin ? "my-3" : ""}
      {...props}
    >
      {errorMsg}
    </CAlert>
  );
};

export default ErrorAlert;
