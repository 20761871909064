import CIcon from "@coreui/icons-react";
import {
  CDropdown,
  CDropdownDivider,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import { useHistory } from "react-router-dom";
import ProfileImage from "../ProfileImage";

import "./style.css";
import { logout } from "../../pages/Logout/Logout";
import { useSelector } from "react-redux";
import { store_getAuthUser } from "../../pages/Auth/Auth.store";
import NotificationsDot from "./../Notifications/NotificationsDot";

const NavbarMenu = () => {
  const history = useHistory();

  // User info
  const user = useSelector(store_getAuthUser);
  const { name, email } = user || {};

  return (
    <CDropdown>
      <CDropdownToggle
        style={{ position: "relative" }}
        className="profile-dropdown-toggle m-0 mr-2 mr-md-4 px-0"
      >
        <ProfileImage size={32} user={user} />
        <NotificationsDot absolute />
      </CDropdownToggle>
      <CDropdownMenu id="dropdown-profile">
        <div className="d-flex justify-content-center my-3">
          <ProfileImage size={56} user={user} />
        </div>
        <p className="title">{name}</p>
        <p className="subtitle">{email}</p>
        <CDropdownItem onClick={() => history.push("/profile")}>
          <CIcon
            name="cil-user"
            style={{ color: "f2f2f2", marginRight: "1rem" }}
          />
          <NotificationsDot />
          Profile
        </CDropdownItem>
        <CDropdownDivider />
        <CDropdownItem onClick={logout}>
          <CIcon
            name="cil-account-logout"
            style={{ color: "f2f2f2", marginRight: "1rem" }}
          />
          Logout
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default NavbarMenu;
