import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { isLoggedIn, setLocalStore } from "utils/localStorage";
import { useAuthMutation } from "pages/Auth/Auth.query";
import { getErrorMessage } from "utils/utils";
import { UserModel } from "models";
import { useDispatch } from "react-redux";
import { store_setAuthData } from "pages/Auth/Auth.store";
import API from "service";
import { Alert } from "utils";

const LoginFormLogic = () => {
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const expired = query.get("expired");

  const history = useHistory();
  if (isLoggedIn()) history.push("/");

  // Login data
  const [state, setState] = useState({
    username: "",
    password: "",
  });

  const handleChange = (e) =>
    setState({ ...state, [e.target.name]: e.target.value });

  // handle login
  const dispatch = useDispatch();
  const { isLoading, mutate: login, error } = useAuthMutation();

  const handleLogin = (e) => {
    e.preventDefault();
    const body = { ...state, username: state.username?.trim() };
    login(body, { onSuccess: onLoginSuccess });
  };

  const onLoginSuccess = (data) => {
    const user = new UserModel(data);
    if (!user.hasAccessToPortal())
      return Alert.error("You don't have access to the portal");

    dispatch(store_setAuthData(data));
    setLocalStore({
      identifier: data?.identifier,
      token: data?.token,
      dashboardType: data?.dashboardType,
      baseUrl: data?.baseUrl,
    });
    API.setToken(data?.token);
    API.setBaseUrl(data?.baseUrl);
    history.push("/home");
  };

  const errorMsg = error ? getErrorMessage(error) : "";

  return { expired, error, isLoading, errorMsg, handleLogin, handleChange };
};

export default LoginFormLogic;
