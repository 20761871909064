import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getDeviceDataByNameService,
  getDevicesPerUserService,
  getListOfThriversService,
  linkDevicesWithThriverService,
  getLocationsService,
  createLocationService,
  updateDeviceDataService,
  deleteLocationService,
  deleteRoomService,
  createRoomsService,
  updateLocationService,
  updateRoomService,
  getRoomsCatalogService,
  getApplianceCatalogService,
  getCountriesCatalogService,
  getThriverInsightsService,
} from "./IoT.service";
import { Alert } from "utils";

const iotKeys = {
  thrivers: "iot-thrivers",
  deviceData: "iot-device-data",
  devices: "iot-devices-list",
  locations: "iot-locations",
  rooms: "rooms-catalog",
  appliance: "appliance-catalog",
  countries: "countries-catalog",
  insights: "iot-insights",
};

// Devices

export const useGetDevicesPerUserQuery = () =>
  useQuery(iotKeys.devices, getDevicesPerUserService);

export const useGetDeviceDataByNameMutation = () =>
  useMutation(({ identifier }) => getDeviceDataByNameService({ identifier }), {
    onSuccess: () => Alert.success("Device found"),
  });

export const useGetDeviceDataByNameQuery = ({ identifier }) =>
  useQuery([iotKeys.deviceData, identifier], () =>
    getDeviceDataByNameService({ identifier })
  );

export const useLinkDeviceWithThriverMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ userId, devicesID }) =>
      linkDevicesWithThriverService({ userId, devicesID }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(iotKeys.devices);
        Alert.success("Device linked to thriver");
      },
    }
  );
};

export const useUpdateDeviceDataMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(({ device }) => updateDeviceDataService({ device }), {
    onSuccess: (response, { device }) =>
      queryClient.invalidateQueries([iotKeys.deviceData, device?.name]),
  });
};

// Locations

export const useGetLocationsQuery = () =>
  useQuery(iotKeys.locations, getLocationsService);

export const useCreateLocationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(({ location }) => createLocationService({ location }), {
    onSuccess: () => {
      Alert.success("Location created");
      queryClient.invalidateQueries(iotKeys.locations);
    },
  });
};

export const useUpdateLocationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(({ location }) => updateLocationService({ location }), {
    onSuccess: () => {
      Alert.success("Location updated");
      queryClient.invalidateQueries(iotKeys.locations);
    },
  });
};

export const useDeleteLocationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ locationId }) => deleteLocationService({ locationId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(iotKeys.locations);
        Alert.success("Location deleted");
      },
    }
  );
};

// Rooms

export const useCreateRoomsMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ locationId, rooms }) => createRoomsService({ locationId, rooms }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(iotKeys.locations);
        Alert.success("Rooms added");
      },
    }
  );
};

export const useUpdateRoomMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ room, locationId }) => updateRoomService({ room, locationId }),
    {
      onSuccess: () => {
        Alert.success("Room updated");
        queryClient.invalidateQueries(iotKeys.locations);
      },
    }
  );
};

export const useDeleteRoomMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ locationId, roomId }) => deleteRoomService({ locationId, roomId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(iotKeys.locations);
        Alert.success("Room deleted");
      },
    }
  );
};

// Catalogs

export const useGetListOfThriversQuery = () =>
  useQuery(iotKeys.thrivers, getListOfThriversService, {
    staleTime: 20 * 1000,
  });

export const useGetRoomsCatalogQuery = () =>
  useQuery(iotKeys.rooms, getRoomsCatalogService, {
    staleTime: 1000 * 60 * 5,
  });

export const useGetApplianceCatalogQuery = () =>
  useQuery(iotKeys.appliance, getApplianceCatalogService, {
    staleTime: 1000 * 60 * 2,
  });

export const useGetCountriesCatalogQuery = () =>
  useQuery(iotKeys.countries, getCountriesCatalogService, {
    staleTime: 1000 * 60 * 40,
  });

// Insights

export const useGetThriverInsightsQuery = ({ circleId }) => {
  return useQuery(
    [iotKeys.insights, circleId],
    () => getThriverInsightsService({ circleId }),
    {
      staleTime: 1000 * 60 * 5,
    }
  );
};
