import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { getFormattedDate } from "utils/dates";
import DayElement from "./DayElement";

const FrequencyCalendar = ({ days = [], countDays = false }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    if (days?.length > 0) {
      const newData = {};
      days
        .map((date) => getFormattedDate(date))
        .forEach((day) => {
          if (!(day in newData)) {
            newData[day.toString()] = {
              log: true,
            };
            if (countDays) newData[day.toString()].total = 1;
          } else if (day in newData && countDays) {
            newData[day.toString()].total++;
          }
        });
      setData(newData);
    } else setData({});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days]);

  return (
    <div style={{ minWidth: "280px" }}>
      <Calendar
        tileContent={({ date, view }) =>
          view === "month" && <DayElement date={data[getFormattedDate(date)]} />
        }
        maxDate={new Date()}
        minDetail="decade"
      />
    </div>
  );
};

export default FrequencyCalendar;
