import React from "react";
import { Colors } from "utils";

const InfoButton = ({ external = false, data = "", title = "" }) => {
  // External link
  if (external && data)
    return (
      <a href={data} target="_blank" rel="noreferrer">
        <IconButton title={title} />
      </a>
    );

  return (
    <div>
      <IconButton title={title} />
    </div>
  );
};

export default InfoButton;

const IconButton = ({ title }) => {
  return (
    <div
      style={{
        width: "18px",
        height: "18px",
        color: Colors._white,
        background: Colors._black,
        borderRadius: "50%",
        textAlign: "center",
      }}
      className="d-flex justify-content-center align-items-center"
      title={title}
    >
      ?
    </div>
  );
};
