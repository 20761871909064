import { CHeader, CToggler } from "@coreui/react";

import { GlobalQueryLoader, NavbarMenu, ViewAsIcon } from "components";
import TheHeaderLogic from "./Header.logic";

import "./Header.scss";

const TheHeader = () => {
  const { userName, dayStatus, onTogglerClick } = TheHeaderLogic();
  return (
    <CHeader className="header">
      <div className="left-side">
        {/* Sidebar toggler */}
        <CToggler
          inHeader
          className="ml-md-3 d-lg-none"
          onClick={onTogglerClick}
        />
        <div className="d-none d-sm-block ml-0 ml-lg-4">
          {/* Greeting */}
          <span className="greet">
            <span>{`Good ${dayStatus}${userName ? ", " : "!"} `}</span>
            {userName && <b> {userName}</b>}
          </span>
        </div>
      </div>

      <div className="right-side">
        {/* Shows loader when a query is active */}
        <GlobalQueryLoader />
        {/* For super admin to remind if is using viewAs feature */}
        <ViewAsIcon />
        <NavbarMenu />
      </div>
    </CHeader>
  );
};

export default TheHeader;
