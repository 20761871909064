import { TheContent, TheHeader, TheSidebar } from ".";

const Layout = () => {
  return (
    <>
      <div className="c-app c-default-layout">
        <TheSidebar />
        <div className="c-wrapper">
          <TheHeader />
          <div className="c-body">
            <TheContent />
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
