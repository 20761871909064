import API from "service";
import { logoutExpired } from "../Logout/Logout";
import { Role } from "utils";

export async function loginService(request) {
  const url = "open/onboarding/sign-in";
  const { data } = await API.post(url, request);
  return data;
}

export async function getInfoService() {
  try {
    const identifier = Role.getId();
    const { data } = await API.get(`secure/personal/profile/${identifier}`);
    return data;
    //
  } catch (error) {
    // If code === 6 the session has expired
    if (error?.response?.data?.code === 6) logoutExpired();
    throw error;
  }
}
