import { CBadge } from "@coreui/react";
import { Colors } from "utils";

const StatusBadge = ({ status }) => {
  return !status ? null : (
    <div
      style={{
        position: "absolute",
        bottom: 0,
        right: 0,
        transform: "translateY(6px)",
      }}
    >
      <CBadge
        style={{
          color: "#f2f2f2",
          background: Colors.getStatusColor(status),
          marginTop: "1rem",
          fontWeight: 500,
        }}
      >
        Status: {status}
      </CBadge>
    </div>
  );
};

export default StatusBadge;
