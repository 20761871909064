export const logo = [
  "1066 320",
  `
  <svg width="1000" height="300" viewBox="0 0 542 146" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M181.1 51.7H160.2V38.8H220V51.7H197.5V111.9H181.1V51.7Z" fill="white"/>
<path d="M224.2 46.1001L239.9 37.4001V65.8001C241 63.4001 243.5 61.3002 247.4 59.6002C249.9 58.5002 252.3 58.0001 254.3 58.0001C261 58.0001 265.9 59.8001 268.9 63.4001C271.9 67.0001 273.5 73.0001 273.5 81.2001V111.9H257.8V81.7001C257.8 74.7001 254.8 71.2001 248.9 71.2001C244.4 71.2001 241.4 72.4001 239.9 74.7001V111.9H224.2V46.1001Z" fill="white"/>
<path d="M296.5 59.5L298 63.8C298.7 62.3 300.1 60.9999 302.3 59.8999C304.5 58.6999 306.6 58.2 308.8 58.2C316 58.2 321.3 61.7 324.7 68.7C323.4 69.4 321.2 70.5 318.1 72.2C315.2 73.8 313 75 311.5 75.8C309 72.8 306.3 71.3 303.4 71.3C301.2 71.3 299.6 72.4999 298.7 74.8999V112.1H283.2V59.6H296.5V59.5Z" fill="white"/>
<path d="M335.8 55.7C333 55.7 330.8 54.8 329 52.9C327.2 51.1 326.3 48.7 326.3 45.7C326.3 43 327.2 40.7 329.1 38.7C331 36.7 333.2 35.8 335.7 35.8C338.5 35.8 340.9 36.8 342.9 38.7C344.8 40.6 345.8 43 345.8 45.7C345.8 48.6 344.9 51 343 52.9C341.2 54.7 338.8 55.7 335.8 55.7ZM344.1 59.5V112H328.3V59.5H344.1Z" fill="white"/>
<path d="M380.6 111.9H368.7L348.1 59.4001H363.8L374.3 92.6002L385.6 59.4001H402L397 71.6002C390 89.4002 384.5 102.8 380.6 111.9Z" fill="white"/>
<path d="M414 55.7C411.2 55.7 409 54.8 407.2 52.9C405.4 51.1 404.5 48.7 404.5 45.7C404.5 43 405.4 40.7 407.3 38.7C409.2 36.7 411.4 35.8 413.9 35.8C416.7 35.8 419.1 36.8 421.1 38.7C423 40.6 424 43 424 45.7C424 48.6 423.1 51 421.2 52.9C419.4 54.7 417 55.7 414 55.7ZM422.3 59.5V112H406.5V59.5H422.3Z" fill="white"/>
<path d="M446.5 64.6001C448 62.8001 450.3 61.3001 453.4 60.0001C456.5 58.7001 459.4 58.1001 462.1 58.1001C468.8 58.1001 473.6 59.9001 476.5 63.4001C479.4 66.9001 480.8 72.9001 480.8 81.2001V111.9H465V81.7001C465 74.7001 461.9 71.2001 455.6 71.2001C454 71.2001 452.3 71.5001 450.6 72.1001C448.9 72.7001 447.7 73.4001 447.1 74.2001V111.9H431.4V59.4001H444.8L446.5 64.6001Z" fill="white"/>
<path d="M525.4 105.4C521.9 110.7 517.1 113.4 510.8 113.4C503.1 113.4 497.1 110.7 492.9 105.2C489.4 100.7 487.6 94.8999 487.6 87.8999C487.6 77.6999 490.6 69.8 496.5 64.3C501 60.2 506.2 58.2 512.2 58.2C517.2 58.2 521.4 59.3999 524.9 61.8999C527.3 63.6999 528.3 64.8 528 65.3L529.2 59.6H541.2V106.5C541.2 115.2 539 122 534.6 126.8C530.2 131.7 523.9 134.1 515.8 134.1C507.1 134.1 499.8 131.2 493.8 125.6L497.6 117.7C502.3 120.7 507.6 122.2 513.5 122.2C521.4 122.2 525.3 116.7 525.3 105.6V105.4H525.4ZM525.4 89.3V78C525.2 75.6 524.1 73.6 522.2 71.8C520.3 70.1 518.1 69.2 515.7 69.2C512 69.2 509.1 70.7 507 73.6C504.9 76.5 503.8 80.5 503.8 85.6C503.8 96.6 507.3 102.1 514.2 102.1C517.3 102.1 519.9 100.9 522.1 98.5C524.3 96.1 525.4 93.2 525.4 89.8C525.4 89.7 525.4 89.5 525.4 89.3Z" fill="white"/>
<path opacity="0.65" d="M72.6 145.9C56.9 145.9 42.3 140.9 30.4 132.4L39.6 124.5C49.1 130.7 60.4 134.2 72.6 134.2C97.2 134.2 118.5 119.5 128 98.3999L138.8 102.9C127.6 128.3 102.2 145.9 72.6 145.9Z" fill="white"/>
<path opacity="0.85" fill-rule="evenodd" clip-rule="evenodd" d="M35.6 11.1001C14.4 23.7001 0.0999756 46.9001 0.0999756 73.4001C0.0999756 92.7001 7.59996 110.2 19.9 123.2L28.8 115.5C18.3 104.6 11.8 89.7001 11.8 73.4001C11.8 50.8001 24.1 31.1001 42.4 20.7001L35.6 11.1001Z" fill="white"/>
<path opacity="0.5" d="M145.1 73.3999C145.1 78.9999 144.5 84.4999 143.2 89.7999L132.2 85.1999C133 81.3999 133.3 77.3999 133.3 73.3999C133.3 39.7999 106.1 12.5999 72.5 12.5999C66.5 12.5999 60.8 13.4999 55.3 15.0999L48.2 5.0999C55.8 2.3999 64 0.899902 72.5 0.899902C112.7 0.899902 145.1 33.3999 145.1 73.3999Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M40.3 44.7C36.7 43.7 33 45.8 31.9 49.4C30.9 53 32.9 56.6 36.5 57.6L58.3 63.4V117.6C58.3 121.3 61.3 124.4 65 124.5C68.7 124.6 71.7 121.6 71.7 117.9V58.4C71.7 55.5 69.9 53 67.3 52C67 51.9 66.7 51.8 66.4 51.7L40.3 44.7Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M88.2 64.2V118.8C88.2 122.5 85.2 125.5 81.5 125.4C77.8 125.3 74.8 122.2 74.8 118.5V59.7C74.8 59.4 74.8 59.1 74.8 58.8C74.9 55.9 76.8 53.6 79.4 52.8C79.6 52.7 79.7 52.7 79.9 52.6L105.5 45.7C109.1 44.7 112.7 46.9 113.6 50.5C114.5 54.1 112.3 57.8 108.7 58.8L88.2 64.2Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M46.8 30.0999C43.2 29.0999 39.5 31.1999 38.4 34.7999C37.4 38.3999 39.4 41.9999 43 42.9999L70.9 50.4999C72.1 50.7999 73.2 50.7999 74.3 50.4999C74.6 50.4999 74.9 50.3999 75.2 50.2999L103.2 43.0999C106.8 42.1999 108.9 38.4999 107.9 34.9999C106.9 31.3999 103.2 29.2999 99.6 30.1999L72.8 36.9999L46.8 30.0999Z" fill="white"/>
</svg>

`,
];
