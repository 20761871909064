import CIcon from "@coreui/icons-react";
import { CAlert, CCol, CRow } from "@coreui/react";

const CopyToClipboard = ({ textToShow, textToCopy }) => {
  return (
    <div className="w-100">
      <CAlert color="success">
        <CRow>
          <CCol sm="11">
            <span className="pr-2">{textToShow}</span>
          </CCol>
          <CCol sm="1">
            <CIcon
              name="cil-copy"
              className="float-right"
              onClick={() => copyToClipboardFunction(textToCopy)}
              style={{ cursor: "pointer" }}
            />
          </CCol>
        </CRow>
      </CAlert>
    </div>
  );
};

export default CopyToClipboard;

export const copyToClipboardFunction = (copyText) => {
  const el = document.createElement("textarea");
  el.value = copyText;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  alert("Copied the text: " + copyText);
};
