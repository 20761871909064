import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useGetInfoQuery } from "pages/Auth/Auth.query";
import { store_setShowSidebar } from "store/settings.store";
import { isLoggedIn } from "utils/localStorage";
import { store_getAuthUser } from "pages/Auth/Auth.store";
const TheHeaderLogic = () => {
  const dispatch = useDispatch();
  const { refetch: updateInfo } = useGetInfoQuery();

  // Day time
  function generateGreeting() {
    let clock = new Date();
    let hour = parseInt(clock.getHours());
    return hour >= 5 && hour <= 11
      ? "Morning"
      : hour >= 12 && hour <= 18
      ? "Afternoon"
      : (hour >= 17 && hour <= 23) || (hour >= 0 && hour <= 4)
      ? "Evening"
      : "Welcome";
  }

  // User name
  const user = useSelector(store_getAuthUser);
  const userName = user?.name || "";

  // Updates the client info and checks if sessions has expired
  useEffect(() => {
    if (isLoggedIn) updateInfo();
  }, [updateInfo]);

  // Controls the side menu visibility
  const onTogglerClick = () => dispatch(store_setShowSidebar(true));
  const dayStatus = generateGreeting();

  return { userName, dayStatus, onTogglerClick };
};

export default TheHeaderLogic;
