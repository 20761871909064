import { useState, useMemo, useEffect } from "react";
import { CDataTable, CRow, CSelect } from "@coreui/react";

import { Card } from "components";
import "./Table.scss";
import Pagination from "./Pagination";

const Table = ({
  data = [],
  fields = [],
  columnDefinitions = {},
  label = "Items",
  loading = false,
  columnFilter = true,
  showHeader = true,
  initialItemsPerPage = 10,
  headerItems = <></>,
  topTableComponent = <></>,
  onRowClick = () => null,
  onSorterValueChange = () => null,
  hover = true,
}) => {
  // Pagination
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(initialItemsPerPage);

  const changeItemsPerPage = (e) => {
    const value = parseInt(e.target.value);
    setItemsPerPage(value);
  };

  const totalPages = useMemo(
    () => Math.ceil(data?.length / itemsPerPage) || 1,
    [itemsPerPage, data]
  );

  useEffect(() => {
    if (activePage > totalPages) setActivePage(totalPages);
  }, [activePage, totalPages]);

  const handlePageChange = (page) => setActivePage(page);

  const formattedData = useMemo(
    () =>
      data?.map((x) => ({
        ...x,
        _classes: `new-table-row ${hover ? "customHover" : ""}`,
      })),
    [data, hover]
  );

  return (
    <Card className="mb-0 pt-0 new-custom-table-container">
      <CRow className="d-flex px-3 pb-3 pt-2 justify-content-between align-items-center flex-wrap">
        <span style={{ fontWeight: "600" }}>
          {label} ({data.length})
        </span>
        <div className="d-flex justify-content-end align-content-center gap-10">
          {headerItems}
          <CSelect
            style={{
              width: "70px",
              padding: "0.3rem 0.7rem",
              display: "inline-block",
              cursor: "pointer",
            }}
            onChange={changeItemsPerPage}
            value={itemsPerPage}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="75">75</option>
            <option value="100">100</option>
          </CSelect>
        </div>
      </CRow>

      <div className="p-0 m-0" style={{ border: "none" }}>
        {topTableComponent}
        <CDataTable
          items={formattedData}
          fields={fields}
          loading={loading}
          responsive
          sorter
          columnFilter={columnFilter}
          itemsPerPage={itemsPerPage}
          activePage={activePage}
          addTableClasses="table-sm new-custom-table"
          scopedSlots={columnDefinitions}
          onRowClick={onRowClick}
          onSorterValueChange={onSorterValueChange}
          header={showHeader}
        />

        <Pagination
          activePage={activePage}
          onActivePageChange={handlePageChange}
          pages={totalPages}
        />
      </div>
    </Card>
  );
};

export default Table;
