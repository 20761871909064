import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeFilters: 0,
};

export const slice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    store_cleanActiveFilter: (state) => {
      state.activeFilters = 0;
    },
    store_setActiveFilters: (state, action) => {
      state.activeFilters = action.payload;
    },
  },
});

export const store_getActiveFilter = (store) => store.filters.activeFilters;

export const { store_cleanActiveFilter, store_setActiveFilters } =
  slice.actions;
export default slice.reducer;
