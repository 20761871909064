import API from "service";

// Devices

export async function getDevicesPerUserService() {
  const url = `/secure/smart-things`;
  const { data } = await API.get(url);
  return data.data
    ?.filter((t) => t.devices?.length > 0)
    ?.map(({ user, devices }) => ({ ...user, devices }));
}

export async function getDeviceDataByNameService({ identifier }) {
  if (!identifier) return;
  const url = `/secure/smart-things/device/${identifier}`;
  const { data } = await API.get(url);
  if (data.code !== 0) throw new Error(data.message);
  return data.data;
}

export async function linkDevicesWithThriverService({ userId, devicesID }) {
  const url = `/secure/smart-things/thriver/${userId}`;
  const { data } = await API.put(url, { devices: devicesID });
  if (data.code !== 0) throw new Error(data.message);
  return data;
}

export async function updateDeviceDataService({ device }) {
  const url = `/secure/smart-things/device/${device.identifier}`;
  const { data } = await API.put(url, device);
  return data;
}

// Locations

export async function getLocationsService() {
  const url = "/secure/smart-things/location";
  const { data } = await API.get(url);
  return data.data;
}

export async function createLocationService({ location }) {
  const url = "/secure/smart-things/location";
  const { data } = await API.post(url, location);
  return data;
}

export async function updateLocationService({ location }) {
  const url = `/secure/smart-things/location/${location?.identifier}`;
  const { data } = await API.put(url, location);
  return data;
}

export async function deleteLocationService({ locationId }) {
  const url = `/secure/smart-things/location/${locationId}`;
  const { data } = await API.del(url);
  return data;
}

// Rooms

export async function createRoomsService({ locationId, rooms }) {
  const url = `/secure/smart-things/location/${locationId}/room`;
  const { data } = await API.post(url, { rooms });
  return data;
}

export async function updateRoomService({ room, locationId }) {
  const url = `/secure/smart-things/location/${locationId}/room/${room?.identifier}`;
  const { data } = await API.put(url, room);
  return data;
}

export async function deleteRoomService({ locationId, roomId }) {
  const url = `/secure/smart-things/location/${locationId}/room/${roomId}`;
  const { data } = await API.del(url);
  return data;
}

// Catalogs

export async function getListOfThriversService() {
  // TODO ask backend for endpoint
  const url = `secure/dashboard/users/get-all`;
  const { data } = await API.get(url);
  return data?.filter((u) => u?.type === "thriver");
}

export async function getRoomsCatalogService() {
  const url = "/open/catalog/room-type";
  const { data } = await API.get(url);
  return data;
}

export async function getApplianceCatalogService() {
  const url = "/open/catalog/appliance-type";
  const { data } = await API.get(url);
  return data;
}

export async function getCountriesCatalogService() {
  const url = "/secure/catalog/country";
  const { data } = await API.get(url);
  return data;
}

// Insights

export async function getThriverInsightsService({ circleId }) {
  const url = `/v4/secure/mobile/smart-things/circle/${circleId}`;
  const { data } = await API.get(url);
  return data?.data;
}
