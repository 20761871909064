import axios from "axios";
import {
  getLocalStorageBaseUrl,
  getLocalViewAs,
  getLocalStore,
} from "utils/localStorage";

const API = (function () {
  const params = { isFromPortal: true };

  const api = axios.create({ params });

  function setBaseUrl(baseUrl) {
    if (baseUrl) api.defaults.baseURL = baseUrl;
  }

  function setToken(token) {
    const localToken = getLocalStore()?.token;
    const auth = token || localToken || "";
    api.defaults.headers.Authorization = `Bearer ${auth}`;
  }

  // Interceptor for error codes
  api.interceptors.response.use(function (response) {
    const { data } = response;
    if (data?.code < 0) throw response;
    return response;
  });

  // View as query param to make requests as careHome
  function setViewAs() {
    const viewAs = getLocalViewAs();
    if (viewAs && viewAs?.identifier)
      api.defaults.params = {
        ...api.defaults.params,
        viewAs: viewAs.identifier,
      };
  }

  // Initial configuration calls
  setBaseUrl(getLocalStorageBaseUrl());
  setToken();
  setViewAs();

  const multipartHeader = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  // REST methods

  const get = (url, config) => api.get(url, config);
  const post = (url, body, config) => api.post(url, body, config);
  const put = (url, body, config) => api.put(url, body, config);
  const del = (url, config) => api.delete(url, config);

  // with multipart header

  const postMultipart = (url, body, config) =>
    api.post(url, body, { ...config, ...multipartHeader });

  const putMultipart = (url, body, config) =>
    api.put(url, body, { ...config, ...multipartHeader });

  return {
    multipartHeader,
    setToken,
    setViewAs,
    setBaseUrl,
    // REST methods
    get,
    post,
    put,
    del,
    //
    postMultipart,
    putMultipart,
  };
})();

export default API;
