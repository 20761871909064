import { useState, useEffect, useMemo } from "react";

import defaultPicture from "../assets/profilepic.svg";
import malePicture from "../assets/maleAvatar.svg";
import femalePicture from "../assets/femaleAvatar.svg";
import frame from "../assets/thrivingCircle.svg";
import { getInitials } from "../utils/strings";
import { getMediaURL } from "../utils/utils";

export const ProfileImage = ({
  size = "120",
  user = {},
  onError = () => null,
  photoValue = "",
}) => {
  const { photo = photoValue, gender, name = "", type } = user;
  const showCircle = useMemo(() => type === "thriver", [type]);

  const [image, setImage] = useState();

  const getPicture = () => {
    if (gender === "m") return malePicture;
    if (gender === "f") return femalePicture;
    return defaultPicture;
  };

  useEffect(() => {
    if (photo) {
      const url = getMediaURL(photo);
      if (url !== image) setImage(url);
    } else setImage(getPicture());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photo]);

  const padding = `${size * 0.2}px`;

  const Photo = () => {
    const noCircleDivStyle = {
      objectFit: "cover",
      width: `${size}px`,
      height: `${size}px`,
    };
    const circleDivStyle = {
      padding: padding,
      background: `url(${frame})`,
      width: `${size}px`,
      height: `${size}px`,
      display: "flex",
      backgroundSize: "cover",
      justifyContent: "center",
      alignItems: "center",
      objectFit: "cover",
    };

    const noCircleImageStyle = {
      width: `${size}px`,
      height: `${size}px`,
      borderRadius: "50%",
      objectFit: "cover",
      objectPosition: "center",
    };
    const circleImageStyle = {
      ...noCircleImageStyle,
      height: `calc(${size}px - ${padding})`,
      width: `calc(${size}px - ${padding})`,
    };

    const lettersStyle = {
      width: `${size}px`,
      height: `${size}px`,
      background: "#2187fb",
      borderRadius: "50%",
      color: "#f2f2f2",
      fontSize: `${size / 2}px`,
      border: "1px solid #f2f2f2",
    };

    return !photo && gender === "u" ? (
      <div
        className="d-flex align-items-center justify-content-center"
        style={lettersStyle}
      >
        {getInitials(name)}
      </div>
    ) : (
      <div style={showCircle ? circleDivStyle : noCircleDivStyle}>
        <img
          alt=""
          src={image}
          style={showCircle ? circleImageStyle : noCircleImageStyle}
          onError={() => {
            setImage(getPicture());
            onError();
          }}
          loading="lazy"
        />
      </div>
    );
  };

  return <Photo />;
};

export default ProfileImage;
