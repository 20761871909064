import moment from "moment";
import { useEffect, useState } from "react";
import { getFormattedDate } from "utils/dates";

const initState = {
  days_7: 0,
  months_1: 0,
  months_3: 0,
  months_6: 0,
  months_12: 0,
};

const FrequencyCardsLogic = ({ days = [] }) => {
  const [data, setData] = useState(initState);

  const checkDate = (amount, unit, date) => {
    if (
      moment(date).isBetween(
        moment().add(-amount, unit),
        moment(),
        "days",
        "[]"
      )
    ) {
      return 1;
    }
    return 0;
  };

  useEffect(() => {
    if (days?.length > 0) {
      const newData = { ...initState };

      const dates = days.map((date) => getFormattedDate(date));

      dates.forEach((date) => {
        newData.days_7 += checkDate(7, "days", date);
        newData.months_1 += checkDate(1, "months", date);
        newData.months_3 += checkDate(3, "months", date);
        newData.months_6 += checkDate(6, "months", date);
        newData.months_12 += checkDate(12, "months", date);
      });
      setData(newData);
    } else setData(initState);
  }, [days]);

  return { data };
};

export default FrequencyCardsLogic;
